import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AsyncDependencyConsumer } from '../base-classes/async-dependency-consumer';
import { AccountSettings, get_interval_in_ms, ProfileReminderInterval } from '../models/people/account-settings.class';

import { AlertService } from './alert.service';
import { AuthService } from './auth.service';
import { BackendCallService } from './backend-call.service';
import { ConfigService } from './config.service';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
export class RemindCheckProfileService extends AsyncDependencyConsumer implements OnDestroy {

  subscriptions: Subscription[] = [];

  constructor(
    private config_service: ConfigService,
    private alert_service: AlertService,
    private backend: BackendCallService,
    private auth: AuthService,
    private router_service: RouterService
  ) {
    super();
    this.init(alert_service, backend, auth, router_service);
  }

  protected onReady() {

    if (this.config_service.config.interval_to_remind_user_of_profile_update === ProfileReminderInterval.INACTIVE) { return; }
    
    this.subscriptions.push(
      this.auth.is_logged_in$().subscribe(logged_in => {
        if (logged_in) {
          this.last_reminder_of_profile_changes();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private async last_reminder_of_profile_changes(): Promise<void> {
    const interval_in_ms = get_interval_in_ms(this.config_service.config.interval_to_remind_user_of_profile_update);
    if (interval_in_ms === null) { return; }  // reminder is inactive

    const new_date = new Date(Date.now() + interval_in_ms).toISOString();
    const url = this.backend.get_backend_domain() + "/api/user/accountsettings/";

    const { last_update_account_information: next_reminder } = await this.backend.get_with_token<AccountSettings>(url);
    if (!next_reminder) {
      return this.backend.put_with_token(url, { last_update_account_information: new_date });
    }

    if (Date.parse(next_reminder) <= Date.now()) {
        this.alert_service.alert({
          header: this.config_service.config.wording.profile_reminder_alert_header,
          message: this.config_service.config.wording.profile_reminder_alert_message,
          buttons: [
            {
              text: "Später",
              role: "cancel"
            },
            {
              text: 'Zum Profil',
              role: "ok",
              handler: () => {
                this.backend.put_with_token(url, { last_update_account_information: new_date });
                this.router_service.navigate(["account", "profile"]);
              }
            }
          ]
        });
      }
  }
}
