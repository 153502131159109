import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { RegistrationCredentials } from "../models/registration/registration-credentials";

import { AccountService } from "./account.service";
import { StorageService } from "./storage.service";
import { TokenService } from "./token.service";

@Injectable({
  providedIn: "root",
})
export class AuthService extends AsyncDependencyBoth implements OnDestroy {
  private subscriptions: Subscription[] = [];

  back_to = "";

  private logged_in: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    undefined
  );

  constructor(
    private acc_service: AccountService,
    private token_service: TokenService,
    private storage_service: StorageService
  ) {
    super();
    this.init(acc_service, token_service, storage_service);
  }

  protected onReady(): void {
    this.subscriptions.push(
      // logged_in === account & token exist properly
      combineLatest([
        this.token_service.get_token$(),
        this.acc_service.get_account$(),
      ])
        .pipe(filter(([token]) => token !== undefined))
        .subscribe(async ([token, account]) => {
          if (!token || !account) {
            await this.logout();
          }

          this.set_logged_in(!!token && !!account?.user);
        })
    );

    this.set_ready();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public is_logged_in$(): Observable<boolean> {
    return this.logged_in.asObservable();
  }

  public is_logged_in(): boolean {
    return this.logged_in.getValue();
  }

  private set_logged_in(logged_in: boolean): void {
    if (this.logged_in.getValue() !== logged_in) {
      this.logged_in.next(logged_in);
    }
  }

  /** login des Nutzers
   * used by login page
   * @param object - Login-Daten
   * @returns object - token
   */
  public async login(credentials: RegistrationCredentials): Promise<boolean> {
    // function get_token also sets a 'token' entry if allowed otherwise saves token in StorageService
    return this.token_service.api_get_token_on_login(credentials).then(token => !!token);
  }

  /**
   * registeriert den Nutzer
   * used by register page
   * @param account object
   * @returns string - Nachricht
   */
  public register(account: {
    email: string;
    password: string;
  }): Promise<string> {
    const error_message = this.acc_service.api_create_account(account);
    this.set_logged_in(!error_message);

    return error_message;
  }

  /**
   * entfernt die Profildaten und Token
   * used by app component & account-overview
   * @returns object - Profil-Daten / leeres Profil
   */
  public async logout(): Promise<void> {
    if (this.is_logged_in()) {
      await this.storage_service.clear_all();
      await this.token_service.remove_token();
    }
  }
}
