<div [ngClass]="{'footer-content--pictogram': config_data?.design?.pictogram}" class="footer-content">
  <div class="button-group">
    <app-button fill="outline" *ngIf="config_data.design.show_faq" [routerLink]="[faq_route]">FAQ</app-button>
    <app-button fill="outline" [routerLink]="['/data-protection']">Datenschutz</app-button>
    <app-button fill="outline" [routerLink]="['/impressum']">Impressum</app-button>
    <app-button fill="outline" *ngIf="has_agb" [routerLink]="['/conditions']">AGB</app-button>
  </div>
</div>
<div class="footer-bottom">
  <img class="image" [src]="config_data?.design?.logos?.footer" alt="logo">

  <div id="version-number">v. {{ version || "1.x.x" }}</div>
  <ion-popover trigger="version-number" triggerAction="hover" id="kroko-popover" show-backdrop=false dismiss-on-select=true>
    <ng-template>
      <ion-content>
        Made with
        <img src="/assets/imgs/slack-kroko.png" alt="kroko">
      </ion-content>
    </ng-template>
  </ion-popover>
</div>