import { Component } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ViewWillEnter } from "@ionic/angular";
import { SentryErrorHandler } from "@sentry/angular";
import { ValidatorService } from "angular-iban";
import { format } from "date-fns";
import { from, merge } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from "rxjs/operators";
import { bic_validator, family_card_validator } from "src/app/components/form/validators";
import { Config } from "src/app/interfaces/config";
import { FeriproGender } from "src/app/models/event/feripro-gender.enum";
import { CustomQuestionType } from "src/app/models/question/custom-question-type.enum";
import { AccessCodeService } from "src/app/services/access-code.service";
import { Payment, PaymentService } from "src/app/services/payment.service";
import { ProgramService } from "src/app/services/program.service";
import * as uuid from 'uuid';

import { AsyncDependencyConsumer } from "../../../base-classes/async-dependency-consumer";
import { Disability } from "../../../models/event/disability.enum";
import { Account } from "../../../models/people/account.class";
import { Child } from "../../../models/people/child.class";
import { Question } from "../../../models/question/custom-question.interface";
import { AccountService } from "../../../services/account.service";
import { AlertService } from "../../../services/alert.service";
import { ChildService } from "../../../services/child.service";
import { ConfigService } from "../../../services/config.service";
import { FamilyCardService } from "../../../services/family-card.service";
import {
  QuestionService,
  AccountType
} from "../../../services/question.service";
import { RouterService } from "../../../services/router.service";

export enum Tab {
  ACCOUNT = "account",
  GUARDIAN = "second_legal_guardian",
  CHILDREN = "children",
  PAYMENT = "payment"
}

@Component({
  selector: "app-profile",
  templateUrl: "./profile.page.html",
  styleUrls: ["./profile.page.scss"],
})
export class ProfilePage extends AsyncDependencyConsumer implements ViewWillEnter {
  account_form: FormGroup;
  second_legal_guardian_form: FormGroup;
  children_form: FormArray;
  payment_form: FormGroup;

  account_data: Account;
  second_legal_guardian: Account;

  account_questions: Question[] = [];
  second_legal_guardian_questions: Question[] = [];
  child_questions: { [child_pk: number]: Question[] } = {};

  completed_forms: Set<AccountType> = new Set<AccountType>();

  config_data: Config;
  all_disabilities: Disability[];

  show_all_errors: boolean;
  first_login: boolean;
  segment_model = Tab.ACCOUNT;

  program_id: number;

  Tab = Tab;
  AccountType = AccountType;
  FeriproGender = FeriproGender;
  CustomQuestionType = CustomQuestionType;
  Object = Object;

  error_messages = {
    required: "Muss ausgefüllt werden.",
    required_true: "Muss zugestimmt werden.",
    email: "Bitte geben Sie eine richtige Email-Adresse an!",
    minlength: "Die Minimallänge ist nicht erreicht.",
    maxlength: "Die Maximallänge wurde überschritten.",
    familycard:
        "Die Freizeitkartennummer stimmt nicht mit dem Namen des Kindes überein. " +
        "Bitte gleichen Sie die Nummer der Wiesbadener Freizeitkarte und die Schreibweise des Namens Ihres Kindes erneut ab.",
    iban: "Bitte geben sie die IBAN Ihres Kontos an.",
    bic: "Bitte geben sie die BIC Ihrer Bank an.",

    invalidForm: " ",
    test: "Test-Validator funktioniert",
  };

  constructor(
    private alert_service: AlertService,
    private acc_service: AccountService,
    private child_service: ChildService,
    private family_card_service: FamilyCardService,
    private config_service: ConfigService,
    private router_service: RouterService,
    private question_service: QuestionService,
    private program_service: ProgramService,
    private payment_service: PaymentService,
    private access_code_service: AccessCodeService,

    private route: ActivatedRoute,

    public formBuilder: FormBuilder
  ) {
    super();
    this.config_data = this.config_service.config;

    this.init(
      alert_service,
      acc_service,
      child_service,
      family_card_service,
      router_service,
      question_service,
      program_service,
      payment_service,
      access_code_service,
    );

    // get first login
    this.ionViewWillEnter();

    // navigate to tab
    const query_tab = Tab[Object.keys(Tab).find(key => Tab[key] === this.route.snapshot.queryParamMap.get("tab"))];
    this.segment_model = query_tab || this.segment_model;
  }

  /**
   * set this.first_login from query params again,
   * in case someone uses the back-arrow of his/her browser and dhe ProfilePage is still undestroyed in cache.
   */
  ionViewWillEnter(): void {
    this.first_login = this.route.snapshot.queryParamMap.get("first_login") === "true";
  }

  protected async onReady(): Promise<void> {

    // all supported disabilities
    this.program_service.get_all_programs$().subscribe(programs =>

      this.all_disabilities = programs
        .reduce((acc, p) => [...acc, ...p.supported_disabilities], [])
        .filter((disability, i, all_disabilities) => all_disabilities.findIndex(d => d.value === disability.value) === i)
    );

    // get data of ..

    // .. account
    this.account_data = this.acc_service.get_account();
    this.account_form = this.get_initial_form_group(this.account_data);

    // .. second_legal_guardian
    if (
      this.config_data.booking_settings?.parent_booking_allowed &&
      this.config_data.booking_settings?.second_legal_guardian_allowed
    ) {
      this.second_legal_guardian = this.acc_service.get_second_legal_guardian() || new Account();
      this.second_legal_guardian_form = this.get_initial_form_group(this.second_legal_guardian);
    }

    // .. children
    const children = this.child_service.get_children();
    this.children_form = this.formBuilder.array(
      children.map(child => this.get_initial_child_form_group(child))
    );

    // questions ..
    this.set_questions();


    // if account's first_name is set, it should be valid alltogether
    if (this.account_data?.first_name) {
      this.completed_forms.add(AccountType.OWNER);
    }
    // if guardian's first_name is set, it should be valid alltogether
    if (this.second_legal_guardian?.first_name) {
      this.completed_forms.add(AccountType.SECOND_LEGAL_GUARDIAN);
    }
    // if at least one child is set, it should be valid alltogether
    if (children?.length) {
      this.completed_forms.add(AccountType.CHILD);
    } else {
      // add empty child form when no child is present
      this.add_child();
    }


    // payment
    if (this.display_payment_tab()) {
      const payment = this.payment_service.get_payment();
      this.payment_form = this.get_payment_form_group(payment);
      this.update_payment_bank_account_owner();
    }


    // program_id for link
    this.program_service.get_current_program$().subscribe(p => this.program_id = p?.program_id);
  }


  // *********** init ************

  private get_initial_form_group(guardian: Account): FormGroup {
    const string_date = guardian.date_of_birth ? format(new Date(guardian.date_of_birth), "dd.MM.yyyy") : "";

    const group = this.formBuilder.group({
      pk: [guardian.pk],
      first_name: [guardian.first_name, [Validators.required]],
      last_name: [guardian.last_name, [Validators.required]],
      gender: [guardian.gender, [Validators.required]],
      date_of_birth_german_format: [string_date, [Validators.required, Validators.pattern(/\d{2}\.\d{2}\.\d{4}/)]],
      date_of_birth: [guardian.date_of_birth, [Validators.required]],
      disabled: [guardian.disabled],
      handicap_selection: [guardian.handicap_selection],
      handicap: [guardian.handicap],
      street: [guardian.street, [Validators.required, Validators.pattern(/^[\w\W-]+\W\d+\w?$/)]],
      zip_code: [guardian.zip_code, [Validators.required, Validators.pattern(/^\d{5}$/)]],
      city: [guardian.city, [Validators.required]],
      district: [guardian.district || ''],
      phone: [guardian.phone, [Validators.required, Validators.maxLength(50)]]
    });

    if (!this.config_data.booking_settings?.parent_booking_allowed) {
      group.controls.date_of_birth_german_format.disable();
      group.controls.date_of_birth.disable();
      group.controls.disabled.disable();
      group.controls.handicap.disable();
    }

    return group;
  }

  private get_initial_child_form_group(child: Child): FormGroup {
    const string_date = child.date_of_birth ? format(new Date(child.date_of_birth), "dd.MM.yyyy") : "";

    let familycard = '';
    if (this.config_service.config.fam_card_active) {
      familycard = this.family_card_service.get_family_cards()[child.pk]?.family_card;
    }

    const child_form = this.formBuilder.group({
      pk: [child.pk],
      first_name: [child.first_name, [Validators.required]],
      last_name: [child.last_name, [Validators.required]],
      gender: [child.gender, [Validators.required]],
      date_of_birth_german_format: [string_date, [Validators.required, Validators.pattern(/\d{2}\.\d{2}\.\d{4}/)]],
      date_of_birth: [child.date_of_birth, [Validators.required]],
      disabled: [child.disabled],
      handicap_selection: [child.handicap_selection],
      handicap: [child.handicap || ""],
      school: [child.school || "", [Validators.required]],
      familycard: [familycard, [Validators.minLength(4), Validators.maxLength(4)]],
      address: this.formBuilder.group({
        street: [child.street, [Validators.required, Validators.pattern(/^[\w\W-]+\W\d+\w?$/)]],
        zip_code: [child.zip_code, [Validators.required, Validators.pattern(/^\d{5}$/)]],
        city: [child.city, [Validators.required]],
        district: [child.district || ''],
        phone: [child.phone, [Validators.required, Validators.maxLength(50)]],
      }),
    });


    if (this.config_data.hide_school_in_profile) {
      child_form.removeControl("school")
    }


    // set up async validator for familycard. Do this manually to control when it is called to minimize the load on the endpoint.
    if (this.config_data.fam_card_active) {
      const controls = [
        child_form.controls.first_name,
        child_form.controls.last_name,
        child_form.controls.familycard,
      ];
      const familycard_control = child_form.controls.familycard;

      merge(...controls.map(c => c.valueChanges)).pipe(
          debounceTime(1000),
          filter(() => controls.every(control => control.valid && control.value)),
          tap(() => familycard_control.markAsPending()),
          switchMap(() => from(family_card_validator(this.family_card_service)(child_form)))
      )
      .subscribe(result => {
        const errors = {...familycard_control.errors, ...result};
        if (!result?.familycard) { delete errors.familycard; }
        
        familycard_control.setErrors(Object.keys(errors).length ? errors : null);
      })
    } else {
      child_form.controls.familycard.disable();
    }

    // set initial state of optional address
    if (child.street) {
      (child_form.controls.address as any).initial_open = true;
    } else {
      child_form.controls.address.disable();
    }

    return child_form;
  }


  private set_questions(): void {

    // children
    for (const child_form_group of (this.children_form.controls as FormGroup[])) {
      const pk: number = child_form_group.value.pk;

      const child_questions =
        this.question_service.get_profile_questions({
          role: AccountType.CHILD,
          pk,
        }) || [];

      this.set_initial_questions(child_questions, child_form_group);

      this.child_questions[pk] = child_questions;
    }

    if (!this.config_data.booking_settings?.parent_booking_allowed) { return; }
    // account

    // get questions & answers
    const account_questions = this.question_service.get_profile_questions({
        role: AccountType.OWNER,
        pk: this.account_data.pk,
      }
    );

    // set in reactive form
    this.set_initial_questions(account_questions, this.account_form);
    // display to the user
    this.account_questions = account_questions;


    if (!this.config_data.booking_settings?.second_legal_guardian_allowed) { return; }
    // guardian

    // get questions & answers
    const second_legal_guardian_questions =
      this.question_service.get_profile_questions({
        role: AccountType.SECOND_LEGAL_GUARDIAN,
        pk: this.second_legal_guardian?.pk,
      });

    // set in reactive form
    this.set_initial_questions(second_legal_guardian_questions, this.second_legal_guardian_form);
    // display to the user
    this.second_legal_guardian_questions = second_legal_guardian_questions;
  }


  // *********** Helpers *************

  private set_initial_questions(questions_with_answers: Question[], form: FormGroup): void {

    // turn the questions into FormControls
    const question_form_controls: {name: string, control: FormControl}[] = this.get_unique_questions(questions_with_answers).map(q => {
      const control = new FormControl(q.answer?.answer || '');
      if (questions_with_answers.some(question => this.are_questions_similar(question, q) && question.question.required)) { control.addValidators(Validators.required); }

      return {name: 'question_'+q.question.id, control};
    }, {});

    // add them to the form
    for (const {name, control} of question_form_controls) {
      if (!form.contains(name)) { form.addControl(name, control); }
    }
  }

  private are_questions_similar(a: Question, b: Question): boolean {
    return a.question.type === b.question.type && a.question.label === b.question.label;
  }

  get_unique_questions(questions: Question[]): Question[] {

    // strip similar questions off. Keep first occurence of similar questions.
    const unique_questions = questions.filter(question =>
      questions.filter(q => this.are_questions_similar(question, q))[0].question.id === question.question.id
    );

    for (const question of unique_questions) {
      question.question.required = questions
        .filter(q => this.are_questions_similar(question, q))
        .some(q => q.question.required);
    }
    return unique_questions;
  }

  toggle_child_address(event: CustomEvent, address: FormGroup): void {
    const accordion = (event.target as any).querySelector("ion-accordion");
    if (!accordion) { return; }

    const icon = accordion.querySelector(".ion-accordion-toggle-icon");
    const opening = !accordion.classList.contains("accordion-expanded");

    opening ? address.enable() : address.disable();
    icon.setAttribute("name", opening ? "trash-outline" : "add");
    icon.setAttribute("color", opening ? "danger" : "");
  }


  is_field_required(field: AbstractControl): boolean {
    return field.validator?.({} as AbstractControl)?.required;
  }

  // ************ CRUD *************

  async delete_second_legal_guardian(): Promise<void> {
    if (!this.config_data.booking_settings?.second_legal_guardian_allowed) {
      return;
    }

    await this.alert_service.alert({
      header: "Löschen?",
      message: `Wollen Sie ${this.second_legal_guardian.first_name} ${this.second_legal_guardian.last_name} wirklich löschen?`,
      buttons: [
        {
          text: "Löschen",
          role: "ok",
          handler: async () => {
            await this.acc_service.api_delete_otherparticipant(this.second_legal_guardian);

            // reset form
            this.second_legal_guardian = this.acc_service.get_second_legal_guardian() || new Account();

            Object.values(this.second_legal_guardian_form.controls)
              .forEach(control => control.setValue(typeof control.value === 'boolean' ? false : ''));

            // reset appereance of error messages
            this.second_legal_guardian_form.markAsUntouched();
            this.second_legal_guardian_form.markAsPristine();
            this.show_all_errors = false;

            this.completed_forms.delete(AccountType.SECOND_LEGAL_GUARDIAN);
          }
        },
        {
          text: "Abbrechen",
          role: "cancel",
        },
      ],
    });
  }

  add_child(): void {
    const child = { ...new Child(), pk: uuid.v4() };
    const child_form = this.get_initial_child_form_group(child as any as Child);
    const child_questions = this.question_service.get_profile_questions(null);

    this.set_initial_questions(child_questions, child_form);
    this.children_form.push(child_form);

    this.child_questions[child.pk] = child_questions;
  }

  async delete_child(child_form: FormGroup): Promise<void> {

    const default_name = '<em>unbekannt</em>';
    const full_name = child_form.value.first_name || child_form.value.last_name ? `${child_form.value.first_name} ${child_form.value.last_name}` : default_name;
    await this.alert_service.alert({
      header: "Löschen?",
      message: `Wollen Sie ${full_name} wirklich löschen?`,
      buttons: [
        {
          text: "Löschen",
          role: "ok",
          handler: async () => {
            const pk = child_form.value.pk;
            if (this.child_service.get_child(pk)) {
              await this.child_service.remove(this.child_service.get_child(pk));
            }

            const index = this.children_form.controls.indexOf(child_form);
            if (index >= 0) {
              this.children_form.removeAt(index);
            }

            // keep one empty form when all children are deleted
            if (!this.children_form.controls?.length && full_name !== default_name) { this.add_child(); }
          }
        },
        {
          text: "Abbrechen",
          role: "cancel",
        },
      ],
    });
  }


  // ********* on submit *********

  public async submit_account(): Promise<void> {
    if (!this.account_form.valid) { this.show_all_errors = true; return; }
    this.show_all_errors = false;

    this.completed_forms.add(AccountType.OWNER);

    // collect values
    const form_values = this.account_form.value;
    const answers = Object.entries(form_values)
      .filter(([key, _]) => key.includes("question_"))
      .reduce((acc, [key, value]) => {
        const question_id = parseInt(key.replace("question_", ""), 10);
        acc[question_id] = value || "";
        delete form_values[key];

        return acc;
      }, {});


    const account: Account = {
      ...this.account_data,
      ...form_values
    };

    // save values
    await this.acc_service.api_update_account(account);

    const questions = this.account_questions
      .map((question) => ({
        ...question,
        answer: {
          ...question.answer,
          answer: answers[this.account_questions.find(q => this.are_questions_similar(question, q)).question.id],
        },
      }));

    await this.question_service.save_answers(questions, {
      role: AccountType.OWNER,
      pk: account.pk,
    });
    return this.after_submit(Tab.ACCOUNT);
  }


  public async submit_second_legal_guardian(): Promise<void> {
    if (!this.second_legal_guardian_form.valid) { this.show_all_errors = true; return; }
    this.show_all_errors = false;

    this.completed_forms.add(AccountType.SECOND_LEGAL_GUARDIAN);

    // collect values
    const form_values = this.second_legal_guardian_form.value;
    const answers = Object.entries(form_values)
      .filter(([key, _]) => key.includes("question_"))
      .reduce((acc, [key, value]) => {
        const question_id = parseInt(key.replace("question_", ""), 10);
        acc[question_id] = value || "";
        delete form_values[key];

        return acc;
      }, {});

    const guardian: Account = {
      ...this.second_legal_guardian,
      ...form_values,
      pk: this.second_legal_guardian.pk
    };

    // save values
    if (guardian.pk) {
      await this.acc_service.api_update_otherparticipant(guardian)
        .catch((err) => SentryErrorHandler.call(err, guardian));
    } else {
      await this.acc_service.api_create_otherparticipant(guardian)
        .catch((err) => SentryErrorHandler.call(err, guardian));
    }

    // update second legal guardian for this page
    this.second_legal_guardian = this.acc_service.get_second_legal_guardian();

    const questions = this.second_legal_guardian_questions
      .map((question) => ({
        ...question,
        answer: {
          ...question.answer,
          answer: answers[this.second_legal_guardian_questions.find(q => this.are_questions_similar(question, q)).question.id],
        },
      }));

    await this.question_service.save_answers(questions, {
      role: AccountType.SECOND_LEGAL_GUARDIAN,
      pk: this.second_legal_guardian.pk,
    });
    return this.after_submit(Tab.GUARDIAN);
  }


  public async submit_children(): Promise<void> {
    if (!this.children_form.valid) { this.show_all_errors = true; return; }
    this.show_all_errors = false;
    this.completed_forms.add(AccountType.CHILD);


    for (const child_form_value of this.children_form.value) {
      const child = { ...child_form_value, ...child_form_value.address };
      delete child.address;
      const pk = child.pk;

      // get familycard
      const familycard = child_form_value.familycard;
      delete child.familycard;

      // get answers
      const answers: {[question_id: number]: string} = Object.entries(child)
        .filter(([key, _]) => key.includes('question_'))
        .reduce((acc, [key, answer]) => {
          const question_id = parseInt(key.replace('question_', ''));
          acc[question_id] = answer;
          delete child[key];

          return acc;
        }, {});


      // save child
      if (this.child_service.get_child(pk)) {
        await this.child_service.update(child);
      } else {
        const created_child = await this.child_service.create(child);
        child.pk = created_child.pk;
      }

      // save familycard
      if (familycard) {
        await this.family_card_service.save(child, { family_card: familycard });
      }

      // save answers
      const child_questions = this.child_questions[pk];
      const questions = child_questions.map(question => ({
        ...question,
        answer: {
          ...question.answer,
          answer: answers[child_questions.find(q => this.are_questions_similar(question, q)).question.id]
        }
      }));

      await this.question_service.save_answers(questions, {
        role: AccountType.CHILD,
        pk: child.pk,
      });
    }
    return this.after_submit(Tab.CHILDREN);
  }


  private async after_submit(current_tab: Tab): Promise<void> {
    const alert = await this.alert_service.alert({
      header: "Erfolg",
      message: "Alle Daten wurden erfolgreich gespeichert.",
      buttons: ["ok"],
    });

    // reload page afterwards. Need to do that for created children to have a pk on reload.
    alert.onDidDismiss().then(() => {
      const query_params = this.route.snapshot.queryParamMap.keys
        .reduce((acc, key) => {
          acc[key] = this.route.snapshot.queryParamMap.getAll(key);
          return acc;
        }, {});

      return this.router_service.navigate("/account/profile", {
        queryParams: {...query_params, tab: current_tab},
        onSameUrlNavigation: "reload"
      }).then(() => this.router_service.reload());
    });
  }

  async complete_registration(): Promise<void> {
    const ask_for_access_code = this.access_code_service.access_code_allowed_in_some_program();
    const buttons = [
      {
        text: "Fertig",
        handler: () => {
          this.first_login = false;
          this.router_service.navigate("/account", {
            queryParams: { first_login: true, complete: true },
          });
        },
      }
    ];

    if (ask_for_access_code) {
      buttons.push({
        text: "Code angeben",
        handler: () => {
          this.first_login = false;
          this.router_service.navigate("/account/access-code", {
            queryParams: { first_login: true, complete: true },
          });
        },
      });
    }

    await this.alert_service.alert({
      header: "Vielen Dank!",
      subHeader: "Sie haben sich erfolgreich angemeldet!",
      message: ask_for_access_code ? "Möchten Sie noch einen Code angeben?" : null,
      buttons
    });
  }






  update_dob(from: AbstractControl, to: AbstractControl): void {
    const set_date = (date: string, control: AbstractControl) => control.value !== date && control.setValue(date);

    try {
      if (!from.value || !from.valid) {
        set_date(null, to);
        return;
      }

      // iso to german
      if (from.value.includes("-")) {
        set_date(format(new Date(from.value), 'dd.MM.yyyy'), to);
        return;
      }

      // german to iso
      if (/\d{2}\.\d{2}\.\d{4}/.test(from.value)) {
        const pad = (num: string, total_length: number) => num.padStart(total_length, '0');

        const [d, m, y]: string[] = from.value.split(".");
        const iso_date = `${pad(y, 4)}-${pad(m, 2)}-${pad(d, 2)}`;
        set_date(iso_date, to);
        return;
      }

      // wrongly formmatted date (is probably german -> iso while typing)
      set_date(null, to);

    } catch(err) {
      if (!(err instanceof RangeError) || err.message !== "Invalid time value") { throw err; }
    }
  }


  /* payment */

  display_payment_tab(): boolean {

    return this.program_service.get_all_programs()
      .map(p => this.payment_service.program_payment_is_only_debit(p))
      .some(only_debit_allowed => only_debit_allowed);
  }

  private get_payment_form_group(payment: Payment): FormGroup {

    return this.formBuilder.group({
      iban: [payment.account_iban, [Validators.required, ValidatorService.validateIban]],
      bic: [payment.account_bic, [Validators.required, bic_validator]],

      my_bank_account: [!payment.account_holder_first_name],

      bank_account_owner: this.formBuilder.group({
        first_name: [payment.account_holder_first_name, [Validators.required]],
        last_name: [payment.account_holder_last_name, [Validators.required]],

        street: [payment.account_holder_street, [Validators.required, Validators.pattern(/^[\w\W-]+\W\d+\w?$/)]],
        zip_code: [payment.account_holder_zip_code, [Validators.required, Validators.pattern(/^\d{5}$/)]],
        city: [payment.account_holder_city, [Validators.required]]
      })
    });
  }
  /** call on change of checkbox */
  update_payment_bank_account_owner(): void {
    const need_info = !this.payment_form.value.my_bank_account;
    const bank_account_owner = this.payment_form.controls.bank_account_owner;
    need_info ? bank_account_owner.enable() : bank_account_owner.disable();
  }

  public async submit_payment(): Promise<void> {

    if (!this.payment_form.valid) { this.show_all_errors = true; return; }
    this.show_all_errors = false;

    const { first_name, last_name, street, zip_code, city } = this.payment_form.value.bank_account_owner || {};

    const data: Payment = {
      id: null,
      account_holder_first_name: first_name,
      account_holder_last_name: last_name,
      account_holder_street: street,
      account_holder_zip_code: zip_code,
      account_holder_city: city,
      account_iban: this.payment_form.value.iban,
      account_bic: this.payment_form.value.bic,
    };

    this.payment_service.update_payment(data).then(async (success) => {

      if (success) {
        const payment = this.payment_service.get_payment();
        this.payment_form = this.get_payment_form_group(payment);

        return this.after_submit(Tab.PAYMENT);
      }
      
      await this.alert_service.alert({
        header: "Etwas ist schief gelaufen",
        message: "Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.",
        buttons: ['Ok'],
      });
    });
  }
}
