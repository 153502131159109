import { Injectable } from "@angular/core";

import { FilterOptions } from "../models/etc/filter-options.class";

import { ConfigService } from "./config.service";

@Injectable({
  providedIn: "root",
})
export class EventFilterDataService {
  /**
   * globally used filter options
   * to be able to show filtersettings (e.g. set on the start page) on any other page
   */
  public options: FilterOptions;

  constructor(private config_service: ConfigService) {
    this.options = new FilterOptions(this.config_service.config);
  }
}
