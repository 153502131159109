import { Injectable } from "@angular/core";
import { Router, NavigationExtras, NavigationEnd } from "@angular/router";
import { NavController } from "@ionic/angular";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";

import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class RouterService extends AsyncDependencyBoth {

  private logged_in: boolean;

  constructor(private router: Router, private navCtrl: NavController, private auth: AuthService) {
    super();
    this.init(auth);
  }

  protected onReady(): void {

    // reload page on logout for security reasons.
    // This will remove presonal & sensitive data from all services
    this.auth.is_logged_in$().subscribe(logged_in => {
      if (this.logged_in && logged_in === false) { this.reload(); }
      this.logged_in = logged_in;
    });
    this.set_ready();
  }

  public reload(): void {
    window.location.reload();
  }

  /**
   * navigate to specified link with optional NavigationExtras
   */
  public async navigate(
    link: string | string[],
    extras: NavigationExtras = {}
  ): Promise<void> {
    link = typeof link === "string" ? [link] : link;
    await this.router.navigate(link, extras);
  }

  public async back(
    back_to?: string,
    extras: NavigationExtras = {}
  ): Promise<void> {
    if (back_to !== undefined) {
      return this.navigate(back_to, extras);
    }
    this.navCtrl.back();
  }


  public get_current_path$(): Observable<string> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    );
  }

  public get_current_path(): string {
    return this.router.url;
  }
}
