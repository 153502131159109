import { AsyncDependencyConsumer } from "./async-dependency-consumer";
import { AsyncDependencyProducer } from "./async-dependency-producer";

/**
 * allows multiple inheritance.
 * copied from official typescript docs: https://www.typescriptlang.org/docs/handbook/mixins.html
 */
function applyMixins(derivedCtor: any, constructors: any[]) {
  constructors.forEach((baseCtor) => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
          Object.create(null)
      );
    });
  });
}

/**
 * Is both AsyncDependencyProducer & AsyncDependencyConsumer.
 *
 * Some classes have to be both at the same time.
 * Problem: no multiple inheritance in js/ts
 * Solution: multi-inherit via Mixin (the AsyncDependencyBoth mixin)
 *
 * Information about mixins: https://www.typescriptlang.org/docs/handbook/mixins.html
 */
export interface AsyncDependencyBoth
  extends AsyncDependencyProducer,
    AsyncDependencyConsumer {}
export class AsyncDependencyBoth {
  constructor() {
    this.start_timeout_for_init();
    this.start_timeout_for_ready();
  }
}

applyMixins(AsyncDependencyBoth, [
  AsyncDependencyProducer,
  AsyncDependencyConsumer,
]);
