import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "search",
})
export class SearchPipe implements PipeTransform {
  transform(
    value: {
      name?: string;
      description?: string;
      teaser?: string;
      price?: string | number;
      duration_summary?: string;
      relative_id?: string;
    },
    search_text: string
  ): boolean {
    if (!value || !search_text?.length) {
      return true;
    }

    search_text = search_text.toLowerCase();
    const properties: string[] = [
      "name",
      "description",
      "teaser",
      "price",
      "duration_summary",
      "relative_id",
    ];

    return properties.some((property) =>
      this.find_in_object(value, property, search_text)
    );
  }

  private find_in_object(
    object,
    property_name: string,
    search_text: string
  ): boolean {
    if (!object || !Object.prototype.hasOwnProperty.call(object, property_name)) {
      return false;
    }

    let value = object[property_name];
    value = value ? value.toString().toLowerCase() : "";
    return value.includes(search_text);
  }
}
