import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { IsRegistrationDateAllowedPipe } from "./is-registration-date-allowed.pipe";

@NgModule({
  declarations: [IsRegistrationDateAllowedPipe],
  imports: [CommonModule],
  exports: [IsRegistrationDateAllowedPipe],
  providers: [IsRegistrationDateAllowedPipe],
})
export class IsRegistrationDateAllowedPipeModule {}
