import { OnDestroy, Injectable } from "@angular/core";
import { Subscription, combineLatest } from "rxjs";
import { filter, map, tap } from "rxjs/operators";

import { Logger } from "../providers/logger";

import { AsyncDependencyProducer } from "./async-dependency-producer";

/**
 * Subclass this if the class dependes on AsyncDependencyProducers, because
 * it makes sure everything is defined properly before use.
 *
 * AsyncDependencyConsumer lets you specify on who to wait and provides a callback (onReady())
 * that is called as soon as every dependency is ready.
 *
 * @example
 *  class A extends AsyncDependencyConsumer {
 *      ...
 *      constructor(
 *          d: AsyncDependencyProducer,
 *          b: AsyncDependencyBoth,
 *          ...) {
 *          super();
 *          ...
 *          this.init(d, b);
 *      }
 *
 *      protected onReady() {
 *          // everything is initialized and ready to use
 *          ...
 *      }
 *      ...
 *  }
 */
@Injectable({
  providedIn: "root",
})
export abstract class AsyncDependencyConsumer implements OnDestroy {
  private timer_until_init;

  private sub: Subscription;
  private are_ready = false;

  constructor() {
    this.start_timeout_for_init();
  }

  /**
   * called in constructor to start a timer.
   * If the time is up, it logs a reminder to call this.init().
   */
  protected start_timeout_for_init(): void {
    const wait_for = 5000;
    this.timer_until_init = setTimeout(() => {
      Logger.warn(
        `Do you call this.init() after constructor completion in ${this.constructor.name}? ` +
          `It has been over ${wait_for} ms.`
      );
    }, wait_for);
  }

  /**
   * Calls this.onReady() if all dependencies are ready. Need to have at least one dependency
   *
   * ! Has to be called in Subclass constructor !
   *
   * (Can not be called from constructor of AsyncDependencyConsumer,
   * because the Subclass' fields may not have been initialized at that point)
   * @callback
   */
  protected init(
    dependency: AsyncDependencyProducer,
    ...other_dependencies: AsyncDependencyProducer[]
  ): void {
    clearTimeout(this.timer_until_init);

    const dependencies = [dependency, ...other_dependencies].filter((d) => d);
    if (!dependencies.length) {
      throw TypeError("No dependencies provided");
    }

    this.sub = combineLatest(dependencies.map((dep) => dep.is_ready$()))
      .pipe(
        map((each_is_ready) => each_is_ready.every((is_ready) => is_ready)),
        filter((are_ready) => this.are_ready !== are_ready),
        tap((are_ready) => (this.are_ready = are_ready)),
        tap((are_ready) => are_ready && this.onReady())
      )
      .subscribe();
  }

  /**
   * Calles as soon as all dependencies are ready
   */
  protected abstract onReady(): any;

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
