<div class="banner" [ngClass]="{'banner-pictogram': config_data.design.pictogram}">

  <!-- title & text -->
  <ion-accordion-group class="banner-inner" *ngIf="markdown_title && markdown_text" [value]="config_data.design.expanded_header_text ? 'first': null">
    <ion-accordion value="first">
      <ion-item slot="header">
        <markdown class="title" [data]="markdown_title" ngPreserveWhitespaces></markdown>
      </ion-item>
      <div class="ion-padding" slot="content">
        <markdown [data]="markdown_text" ngPreserveWhitespaces>
          <!-- innerHtml of component usage -->
          <ng-content></ng-content>
        </markdown>
      </div>
    </ion-accordion>
  </ion-accordion-group>

  <!-- title or text -->
  <div class="banner-inner" *ngIf="!markdown_title || !markdown_text">
    <markdown class="title" *ngIf="markdown_title" [data]="markdown_title" ngPreserveWhitespaces></markdown>
    
    <markdown [data]="markdown_text" ngPreserveWhitespaces>
      <!-- innerHtml of component usage -->
      <ng-content></ng-content>
    </markdown>
  </div>

</div>