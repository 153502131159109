import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import {
  AccessCodeValidation,
  FeriproAccessCodeValidation,
} from "../models/access-key/access-code-validation.interface";
import { AccessCode } from "../models/program/program-extras.interface";
import { Logger } from "../providers/logger";

import { AuthService } from "./auth.service";
import { BackendCallService } from "./backend-call.service";

@Injectable({
  providedIn: "root",
})
export class AllAccessCodesService
  extends AsyncDependencyBoth
  implements OnDestroy
{
  private subscriptions: Subscription[] = [];

  private all_access_codes$: BehaviorSubject<AccessCode[]> =
    new BehaviorSubject<AccessCode[]>([]);

  private logged_in = false;

  public whole_calculated_price = 0;

  constructor(private backend: BackendCallService, private auth: AuthService) {
    super();
    this.init(backend, auth);
  }

  protected async onReady(): Promise<void> {
    this.subscriptions.push(
      this.auth.is_logged_in$().subscribe(async (logged_in) => {
        this.logged_in = logged_in;

        const codes: AccessCode[] = this.logged_in
          ? await this.api_get_access_codes()
          : [];
        this.set_all_access_codes(codes);
      })
    );

    this.set_ready();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_all_access_codes$(): Observable<AccessCode[]> {
    return this.all_access_codes$.asObservable();
  }
  public get_all_access_codes(): AccessCode[] {
    return this.all_access_codes$.getValue();
  }

  private set_all_access_codes(all_access_codes: AccessCode[]): void {
    if (!all_access_codes) {
      return;
    }

    this.all_access_codes$.next(this.logged_in ? all_access_codes : []);
  }

  /**
   * save the given code in our backend
   * @param name of this code. Usually feripro:key_category + key_description. Gets trimmed to max 250 character length
   * @param program_id chosen program id
   * @param code the code to save in the backend
   * @returns boolean - success true / fail false
   */
  public async create(code: AccessCode): Promise<boolean> {
    return this.api_create_access_code(code);
  }

  public check(
    access_code: string,
    program_id: number,
    event_ids: number[] = []
  ): Promise<AccessCodeValidation> {
    return this.api_check_access_code(
      access_code,
      event_ids,
      program_id
    );
  }

  /**
   * entfernt übergebenen Zugangscode aus der DB
   * @param code object
   * @returns boolean - success true
   */
  public delete(code: AccessCode): Promise<boolean> {
    return this.api_delete_access_code(code);
  }

  // *************************************
  // **** Backend API functions start ****
  // *************************************

  // ***** communication with feripro start *****

  /**
   * holt alle zuvor gespeicherten Zugangscodes
   * used by app component, home page, event page, access-code page & participant-registration class
   * @returns array<object> - Zugangscodes oder leeres Array
   */
  private async api_get_access_codes(): Promise<AccessCode[]> {
    const url = `${this.backend.get_backend_domain()}/api/user/accesscodes/`;

    return this.backend.get_with_token<AccessCode[]>(url)
      .catch((err) => {
        Logger.error(err);
        return [];
      });
  }

  /**
   * save the given code in our backend
   * @param name of this code. Usually feripro:key_category + key_description. Gets trimmed to max 250 character length
   * @param program_id chosen program id
   * @param code the code to save in the backend
   * @returns boolean - success true / fail false
   */
  private async api_create_access_code(code: AccessCode): Promise<boolean> {
    const url = `${this.backend.get_backend_domain()}/api/user/accesscodes/`;

    return this.backend
      .post_with_token(url, code)
      .then(async () => {
        // this.message_service.add('Code wurde erfolgreich gespeichert.');
        const access_codes = await this.api_get_access_codes();
        this.set_all_access_codes(access_codes);
        return true;
      })
      .catch(() => false);
  }

  /**
   * entfernt übergebenen Zugangscode aus der DB
   * @param code object
   * @returns boolean - success true
   */
  private api_delete_access_code(code: AccessCode): Promise<boolean> {
    const url = `${this.backend.get_backend_domain()}/api/user/accesscode/${
      code.id
    }`;

    return this.backend.delete_with_token(url).then(() => {
      this.set_all_access_codes(
        this.get_all_access_codes().filter(
          (temp_code) => temp_code.code !== code.code
        )
      ); // delete
      return true;
    });
  }

  /**
   * prüft ob bestimmter Zugangscode, im Programm vorhanden ist
   * Zb checken: Wie viele freie Plätze haben die Events für diesen Zugangscode noch?
   * @param access_code Zugangscode der gecheckt wird
   * @param event_ids array der zu checkenden event ids. In den Veranstaltungen kann eine Zugriffsbeschränkung durch Zugangscode existieren
   * @param program_id die zu checkende program id
   * used by event page, access-code page & participant-registration class
   */
  private api_check_access_code(
    access_code: string,
    event_ids: number[],
    program_id: number
  ): Promise<AccessCodeValidation> {
    const url =
      `${this.backend.get_feripro_backend_domain()}/api/custom/validate/accesskey?program=${program_id}` +
      `&access_key=${access_code}${
        event_ids.length ? "&events=" + event_ids.join(",") : ""
      }&free_slots=true`; // free_slots: soll anzahl freier slots mitgeteilt werden? Manche Zugangscodes sind für mehrere Buchungen / Nutzer gültig

    return this.backend
      .get_with_token<
        // if free_slots === true
        | FeriproAccessCodeValidation
        // if free_slots === false
        | { answer: boolean | string }
      >(url)
      .then((res) => {
        // base case
        const validation: AccessCodeValidation = {
          event_free_slots: undefined,
          event_requested_slots: undefined,
        };

        // handle error case, put message in validation.error
        if (typeof res.answer === "string") {
          validation.error = res.answer;
        }
        delete res.answer;

        // return cleaned object
        return { ...validation, ...res } as AccessCodeValidation;
      })
      .catch(() => ({
        error: "Error: nichts übertragen zur Überprüfung",
        event_free_slots: undefined,
        event_requested_slots: undefined
      }));
  }
}
