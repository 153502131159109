import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { EnvironmentInfoService } from "src/app/services/environment-info.service";

import { AsyncDependencyConsumer } from "../../base-classes/async-dependency-consumer";
import { Config } from "../../interfaces/config";
import { ConfigService } from "../../services/config.service";
import { ProgramService } from "../../services/program.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent
  extends AsyncDependencyConsumer
  implements OnDestroy
{
  config_data: Config;
  has_agb = true;
  faq_route: string;

  version: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private config_service: ConfigService,
    private program_service: ProgramService,
    private env_service: EnvironmentInfoService,
  ) {
    super();
    this.config_data = this.config_service.config;
    this.init(program_service, env_service);
    this.set_faq_route();
  }

  protected onReady(): void {
    this.subscriptions.push(
      this.program_service
        .get_current_program$()
        .subscribe(
          (program) => (this.has_agb = program && !!program.conditions_html)
        )
    );

    this.version = this.env_service.get_app_version();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private set_faq_route(): void {
    const pages = {
      "Grenzach-Wyhlen": "/faq/grenzach-wyhlen",
      Hannover: "/faq/hannover",
      Ingelheim: "/faq/ingelheim",
      Langenfeld: "/faq/langenfeld",
      Marburg: "/faq/marburg",
      Osnabrück: "/faq/osnabrueck",
      Scharbeutz: "/faq/scharbeutz",
      Vogelsbergkreis: "/faq/vogelsbergkreis",
      Wiesbaden: "/faq/wiesbaden",
    };

    this.faq_route = pages[this.config_data.instanz] || "/faq";
  }
}
