import { Pipe, PipeTransform } from "@angular/core";
import { PriceFormat } from "src/app/models/event/price-format.enum";

import { ConfigService } from "../../services/config.service";

@Pipe({
  name: "price",
})
export class PricePipe implements PipeTransform {
  constructor(private config_service: ConfigService) {}

  transform(price: number): string {
    const price_format = this.config_service.config.price_format;

    if (price_format === PriceFormat.HIDE) {
      return "";
    }
    if (!price && price_format === PriceFormat.HIDE_ON_FREE) {
      return "";
    }
    if (!price && price_format === PriceFormat.DISPLAY_FREE_AS_kostenlos) {
      return "kostenlos";
    }

    return `${(price || 0).toFixed(2)} €`;
  }
}
