import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import { AsyncDependencyConsumer } from "../base-classes/async-dependency-consumer";
import { ConfigService } from "../services/config.service";
import { EnvironmentInfoService } from "../services/environment-info.service";
import { RouterService } from "../services/router.service";

@Injectable({
  providedIn: "root",
})
export class MaintenanceGuard
  extends AsyncDependencyConsumer
  implements CanActivate, CanActivateChild
{
  private ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private config_service: ConfigService,
    private env_info_service: EnvironmentInfoService,
    private router_service: RouterService
  ) {
    super();
    this.init(env_info_service, router_service);
  }

  protected onReady(): void {
    this.ready.next(true);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.ready.asObservable().pipe(
      filter((ready) => ready),
      take(1),
      map(() => {
        const is_maintenance_timespan = this.config_service.config.maintenance.active_at.some(timespan =>
          new Date(timespan.start).getTime() <= Date.now() && Date.now() <= new Date(timespan.end).getTime()
        );

        const is_maintenance =
          is_maintenance_timespan ||
          this.config_service.config.maintenance.active ||
          this.env_info_service.is_IE_browser();

        const to_maintenance_page = state.url.includes("maintenance");

        let redirected = false;
        if (is_maintenance && !to_maintenance_page) {
          this.router_service.navigate("/maintenance");
          redirected = true;
        }
        if (!is_maintenance && to_maintenance_page) {
          this.router_service.back("/");
          redirected = true;
        }

        return !redirected;
      })
    );
  }
}
