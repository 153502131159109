<nav *ngIf="!mobile; else mobileView" class="button-bar-right" (click)="onClick && onClick()">
  <a class="navbar-button-badge" href="/account" [routerLink]="['/account']" title="Account">
    <ion-icon name="person-outline" class="icon"></ion-icon>
    <div *ngIf="!(auth.is_logged_in$()|async)" class="badge-info">!</div>
  </a>

  <a class="navbar-button-badge" *ngIf="auth.is_logged_in$()|async" href="/registrations" [routerLink]="['/registrations']" [title]="config_service.config.wording.registrations_page_title">
    <ion-icon name="briefcase-outline" class="icon"></ion-icon>
    <div class="badge-info">{{ count_registration_items }}</div>
  </a>

  <a class="navbar-button-badge" [href]="'/shopping-cart/prio/' + program_id" [routerLink]="['/shopping-cart/prio', program_id]" *ngIf="program_id" title="Warenkorb">
    <ion-icon name="cart-outline" class="icon"></ion-icon>
    <div class="badge-info">{{ count_shopping_cart_items }}</div>
  </a>

  <a class="navbar-button-badge" [href]="'/wishlist/' + program_id" [routerLink]="['/wishlist', program_id]" *ngIf="display_wishlist_button && program_id" title="Wunschliste" data-cy="wishlist_link">
    <ion-icon name="heart-outline" class="icon"></ion-icon>
    <div class="badge-info">{{ count_wishlist_items }}</div>
  </a>
</nav>


<ng-template #mobileView>
  <ion-list class="popover-list" (click)="onClick && onClick()">
    <ion-item [routerLink]="['/account']">
      <ion-icon name="person-outline" slot="start"></ion-icon>
      <ion-label>Account</ion-label>
      <ion-badge *ngIf="!(auth.is_logged_in$()|async)" class="badge-info" slot="end">!</ion-badge>
    </ion-item>

    <ion-item *ngIf="auth.is_logged_in$()|async" [routerLink]="['/registrations']">
      <ion-icon name="briefcase-outline" slot="start"></ion-icon>
      <ion-label>Buchungen</ion-label>
      <ion-badge class="badge-info" slot="end">{{ count_registration_items }}</ion-badge>
    </ion-item>

    <ion-item *ngIf="program_id" [routerLink]="['/shopping-cart/prio', program_id]">
      <ion-icon name="cart" slot="start"></ion-icon>
      <ion-label>Warenkorb</ion-label>
      <ion-badge class="badge-info" slot="end" *ngIf="count_shopping_cart_items">{{ count_shopping_cart_items }}</ion-badge>
    </ion-item>

    <ion-item *ngIf="program_id" [routerLink]="['/wishlist', program_id]" data-cy="wishlist_link">
      <ion-icon name="heart-outline" slot="start"></ion-icon>
      <ion-label>Wunschliste</ion-label>
      <ion-badge class="badge-info" slot="end" *ngIf="count_wishlist_items">{{ count_wishlist_items }}</ion-badge>
    </ion-item>
  </ion-list>
</ng-template>