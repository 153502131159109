import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";
import { Observable, BehaviorSubject, firstValueFrom } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { RegistrationCredentials } from "../models/registration/registration-credentials";
import { Logger } from "../providers/logger";

import { ConfigService } from "./config.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class TokenService extends AsyncDependencyBoth {
  private token_prefix = "Bearer ";
  private backend_domain = "";

  private token: BehaviorSubject<string> = new BehaviorSubject<string>(
    undefined
  );

  constructor(
    private storage_service: StorageService,
    private http: HttpClient,
    private config_service: ConfigService
  ) {
    super();
    this.backend_domain =
      this.config_service.config.urls.backend_domain.replace(/\/$/, "");
    this.init(storage_service);
  }

  protected onReady(): void {
    this.load_token().then((token) => {
      this.set_token(token);
      this.set_ready();
    });
  }

  public get_token$(): Observable<string> {
    return this.token.asObservable();
  }
  public get_token(): string {
    return this.token.getValue();
  }

  private set_token(token: string) {
    if (this.get_token() === token) {
      return;
    }

    this.token.next(token);
  }

  /**
   * get token from backend
   * @param login_data email + password
   * @returns token
   */
  public api_get_token_on_login(
    login_data: RegistrationCredentials
  ): Promise<string> {
    const url = `${this.backend_domain}/auth/login/`;
    const headers = { "Content-Type": "application/json" };

    return firstValueFrom(this.http.post<{ token?: string }>(url, login_data, { headers }))
      .then(({ token }) => this.save_token(token))
      .catch(({ error }) => {
        throw Error(error.non_field_errors);
      });
  }

  /**
   * aktualisiert das Token
   * @param token_data Token string
   * @returns string Token
   */
  public async refresh_token(
    token: string = this.get_token()
  ): Promise<string> {
    const headers = {
      "Content-Type": "application/json",
      Authorization: this.token_prefix + token,
    };
    const url = `${this.backend_domain}/auth/api-token-refresh/`;

    return firstValueFrom(this.http.post<{ token: string }>(url, { token }, { headers }))
      .then((result) => this.save_token(result.token))
      .catch(() => "");
  }

  /**
   * entfernt das Token aus dem Gerätespeicher
   * @returns boolean true if removal was successful, false otherwise
   */
  public async remove_token(): Promise<void> {
    this.set_token("");
    return this.storage_service.remove("token");
  }

  /**
   * Save token
   * @param token string
   * @return saved token, empty string if token empty or save unsuccessful
   */
  private async save_token(token: string): Promise<string> {
    const t = await this.storage_service.set("token", token).catch((error) => {
      Logger.error(`error setting 'token'`, {error});
      return "";
    });

    this.set_token(t);
    return t;
  }

  /**
   * Load token
   * @return saved token, empty string if token empty or save unsuccessful
   */
  private load_token(): Promise<string> {
    return this.storage_service
      .get<string>("token")
      .then(async (token) => this.check(token))
      .catch(() => {
        Logger.log("token could not be found");
        return "";
      });
  }


  /**
   * prüfen ob der Token abgelaufen ist
   * wenn nicht abgelaufen gibt token zurück sonst leerstring
   * @returns string - token oder leerstring
   */
  private async check(token: string): Promise<string> {
    if (!token) {
      Logger.log("Token does not exist");
      return "";
    }

    const decoded_token: { exp: number } = jwt_decode(token);
    const current_time_in_sec: number = new Date().getTime() / 1000; // get current date in sec

    const half_a_day_in_seconds: number = 60 * 60 * 12; // 12h in seconds

    // token expired?
    if (current_time_in_sec >= decoded_token.exp) {
      return this.remove_token().then(() => "");
    }

    // time to refresh token? (since 12h before expiration, see interval_until_refresh)
    if (current_time_in_sec >= decoded_token.exp - half_a_day_in_seconds) {
      return this.refresh_token(token);
    }

    // valid token
    return token;
  }
}
