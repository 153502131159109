import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, BehaviorSubject, combineLatest } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import { AsyncDependencyConsumer } from "../base-classes/async-dependency-consumer";
import { AuthService } from "../services/auth.service";
import { RouterService } from "../services/router.service";

/**
 * Using CanActivate insted of CanLoad here, because the Guard does not evaluate again on Page-revisit.
 * Means a user can log out and still see protected pages. Or he logs in and cant see them.
 * A Page-refresh would fix this, but is not practicable.
 */
@Injectable({
  providedIn: "root",
})
export class AuthGuard
  extends AsyncDependencyConsumer
  implements CanActivate, CanActivateChild
{
  private ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private auth: AuthService,
    private router_service: RouterService
  ) {
    super();
    this.init(auth, router_service);
  }

  protected onReady(): void {
    this.ready.next(true);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    // use only Observables, no BehaviorSubjects. Guard will not evaluate multiple times (for multiple routes)
    return combineLatest([
      this.ready.asObservable(),
      this.auth.is_logged_in$(),
    ]).pipe(
      // proceed only when dependencies are ready &
      // ignore initial value of logged_in. Has no information over login state (jet).
      filter(([ready, logged_in]) => ready && logged_in !== undefined),

      // complete Observable. Is necessary to display page
      take(1),

      // where to go next ...
      map(([_, logged_in]) => {
        if (logged_in) {
          return true;
        }

        this.router_service.navigate("/auth/login", {
          queryParams: { back_to: state.url },
        });
        return false;
      })
    );
  }
}
