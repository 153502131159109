import { Component, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ConfigService } from "src/app/services/config.service";

import { AsyncDependencyConsumer } from "../../../base-classes/async-dependency-consumer";
import { DistributionType } from "../../../models/program/distribution-type.enum";
import { AuthService } from "../../../services/auth.service";
import { BookingService } from "../../../services/booking.service";
import { EnvironmentInfoService } from "../../../services/environment-info.service";
import { ProgramService } from "../../../services/program.service";
import { ShoppingCartService } from "../../../services/shopping-cart.service";
import { WishlistService } from "../../../services/wishlist.service";

@Component({
  selector: "app-collapsible-nav",
  templateUrl: "./collapsible-nav.component.html",
  styleUrls: ["./collapsible-nav.component.scss"],
})
export class CollapsibleNavComponent
  extends AsyncDependencyConsumer
  implements OnDestroy
{
  @Input() mobile: boolean;
  @Input() onClick: () => any;

  private subscriptions: Subscription[] = [];

  edge = false;

  program_id: number;
  account_data: { is_logged_in?: boolean } = {};

  count_shopping_cart_items = 0;
  count_wishlist_items = 0;
  count_registration_items = 0;

  display_wishlist_button = true;

  constructor(
    private program_service: ProgramService,
    private booking_service: BookingService,
    private shopping_cart_service: ShoppingCartService,
    private env_info_service: EnvironmentInfoService,
    private wishlist_service: WishlistService,

    public config_service: ConfigService,
    public auth: AuthService
  ) {
    super();
    this.init(
      program_service,
      booking_service,
      env_info_service,
      shopping_cart_service,
      auth,
      wishlist_service
    );
  }

  protected onReady(): void {
    this.edge = this.env_info_service.is_edge_browser();

    this.subscriptions.push(
      this.program_service.get_current_program$().subscribe((program) => {
        this.program_id = program ? program.program_id : undefined;

        this.display_wishlist_button =
          program && (this.config_service.config.always_display_wishlist || program.distribution_type !== DistributionType.INSTANT);
      }),
      this.wishlist_service.get_wishlist$().subscribe((wishlist) => {
        this.count_wishlist_items = wishlist.length;
      }),
      this.shopping_cart_service
        .get_shopping_cart$()
        .subscribe((shopping_cart) => {
          this.count_shopping_cart_items = shopping_cart.length;
        }),
      this.booking_service.get_uuid_objects$().subscribe((uuid_objects) => {
        this.count_registration_items = uuid_objects.reduce(
          (num_regs, uuid_object) =>
            num_regs + uuid_object.registrations.length,
          0
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
