import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import {
  AccessCodeGuard,
  AccessCodePermission,
} from "./guards/access-code.guard";
import { AuthGuard } from "./guards/auth.guard";
import { CreateProfileGuard } from "./guards/create-profile.guard";
import { CustomerGuard } from "./guards/customer.guard";
import { MaintenanceGuard } from "./guards/maintenance.guard";
import { SetProgramResolverGuard } from "./guards/set-program-resolver.guard";
import { ShoppingCartStepsGuard } from "./guards/shopping-cart-steps.guard";
import { Instance } from "./models/etc/instance";

const routes: Routes = [
  // top level route. Apply global guards here
  {
    path: "",
    component: undefined,
    canActivateChild: [
      MaintenanceGuard,
      CreateProfileGuard,
      SetProgramResolverGuard,
    ],

    // routes
    children: [
      // HOME
      {
        path: "",
        pathMatch: "full",
        loadChildren: () =>
          import("./pages/home/home.module").then((m) => m.HomePageModule),
      },

      // PROGRAM & EVENTS
      {
        path: "program",
        loadChildren: () =>
          import("./pages/program/program.module").then(
            (m) => m.ProgramPageModule
          ),
        canActivateChild: [AccessCodeGuard],
        data: {
          use_access_codes: AccessCodePermission.CURRENT_PROGRAM_IF_RESTRICTED,
        },
      },
      {
        path: "events",
        loadChildren: () =>
          import("./pages/events/events.module").then(
            (m) => m.EventsPageModule
          ),
        canActivateChild: [AccessCodeGuard],
        data: {
          use_access_codes: AccessCodePermission.CURRENT_PROGRAM_IF_RESTRICTED,
        },
      },
      {
        path: "event", // use as in 'event/:event_id'
        loadChildren: () =>
          import("./pages/events/event/event.module").then(
            (m) => m.EventPageModule
          ),
        canActivateChild: [AccessCodeGuard],
        data: {
          use_access_codes: AccessCodePermission.CURRENT_PROGRAM_IF_RESTRICTED,
        },
      },

      // ACCOUNT
      {
        path: "account",
        loadChildren: () =>
          import("./pages/account/account-routing.module").then(
            (m) => m.AccountRoutingModule
          ),
        canActivateChild: [AuthGuard],
      },

      // AUTH
      {
        path: "auth",
        loadChildren: () =>
          import("./pages/auth/auth-routing.module").then(
            (m) => m.AuthRoutingModule
          ),
      },

      // WISHLIST & CART
      {
        path: "wishlist",
        loadChildren: () =>
          import("./pages/wishlist/wishlist.module").then(
            (m) => m.WishlistPageModule
          ),
        canActivateChild: [AccessCodeGuard],
        data: {
          use_access_codes: AccessCodePermission.CURRENT_PROGRAM_IF_RESTRICTED,
        },
      },
      {
        path: "shopping-cart",
        loadChildren: () =>
          import("./pages/shopping-cart/shopping-cart-routing.module").then(
            (m) => m.ShoppingCartRoutingModule
          ),
        canActivateChild: [AuthGuard, ShoppingCartStepsGuard, AccessCodeGuard],
        data: {
          use_access_codes: AccessCodePermission.CURRENT_PROGRAM_IF_RESTRICTED,
        },
      },

      // MEIN FERIENPROGRAMM
      {
        path: "registrations",
        loadChildren: () =>
          import("./pages/registrations/registrations.module").then(
            (m) => m.RegistrationsPageModule
          ),
        canActivateChild: [AuthGuard],
      },

      // AGB, IMPRESSUM, DATA PROTECTION
      {
        path: "data-protection",
        loadChildren: () =>
          import("./pages/data-protection/data-protection.module").then(
            (m) => m.DataProtectionPageModule
          ),
      },
      {
        path: "impressum",
        loadChildren: () =>
          import("./pages/impressum/impressum.module").then(
            (m) => m.ImpressumPageModule
          ),
      },
      {
        path: "conditions",
        loadChildren: () =>
          import("./pages/conditions/conditions.module").then(
            (m) => m.ConditionsPageModule
          ),
      },

      // FAQ
      {
        path: "faq",
        loadChildren: () =>
          import("./pages/faq/faq-routing.module").then(
            (m) => m.FaqRoutingModule
          ),
      },

      // CUSTOM SITES
      {
        path: "custom",
        loadChildren: () =>
          import("./pages/custom-sites/custom-sites-routing.module").then(
            (m) => m.CustomSitesRoutingModule
          ),
      },

      // CUSTOMER-SPECIFIC
      {
        path: "family-card",
        loadChildren: () =>
          import("./pages/custom-sites/family-card/family-card.module").then(
            (m) => m.FamilyCardPageModule
          ),
        canActivate: [CustomerGuard],
        data: {
          allowed_instances: [
            Instance.WIESBADEN,
            Instance.DEVELOP,
            Instance.DEMO,
          ],
        },
      },
      {
        path: "osnabrueck-contact",
        loadChildren: () =>
          import(
            "./pages/custom-sites/osnabrueck-contact/osnabrueck-contact.module"
          ).then((m) => m.OsnabrueckContactPageModule),
        canActivate: [CustomerGuard],
        data: {
          allowed_instances: [
            Instance.OSNABRUECK,
            Instance.DEVELOP,
            Instance.DEMO,
          ],
        },
      },
      {
        path: "osnabrueck-program-booklet",
        loadChildren: () =>
          import(
            "./pages/custom-sites/osnabrueck-program-booklet/osnabrueck-program-booklet.module"
          ).then((m) => m.OsnabrueckProgramBookletPageModule),
        canActivate: [CustomerGuard],
        data: {
          allowed_instances: [
            Instance.OSNABRUECK,
            Instance.DEVELOP,
            Instance.DEMO,
          ],
        },
      },
      {
        path: "wiesbaden-einfache-sprache",
        loadChildren: () =>
          import(
            "./pages/custom-sites/wiesbaden-einfache-sprache/wiesbaden-einfache-sprache.module"
          ).then((m) => m.WiesbadenEinfacheSprachePageModule),
        canActivate: [CustomerGuard],
        data: {
          allowed_instances: [
            Instance.WIESBADEN,
            Instance.DEVELOP,
            Instance.DEMO,
          ],
        },
      },

      // maintenance
      {
        path: "maintenance",
        loadChildren: () =>
          import("./pages/maintenance/maintenance.module").then(
            (m) => m.MaintenancePageModule
          ),
      },

      // 404
      {
        path: "not-found",
        loadChildren: () =>
          import("./pages/not-found/not-found.module").then(
            (m) => m.NotFoundPageModule
          ),
      },

      // Wildcard route redirects to 404 page
      {
        path: "**",
        redirectTo: "not-found",
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
