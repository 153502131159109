<ion-menu side="start" menuId="first" [contentId]="contentId" class="menu-sidebar">
  <div class="logo-container">
    <ion-button class="close-menu-btn" fill="clear" (click)="close_menu()">
      <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
    <div class="logo" *ngIf="config_data?.design?.logos?.top_left">
      <ion-img [src]="config_data.design.logos.top_left" alt="Logo"></ion-img>
    </div>
  </div>

  <ion-content>
    <nav class="menu">

      <ion-accordion-group class="menu-pages" multiple="true">
        <ng-container *ngFor="let page of pages; let i = index">
          <ng-container *ngIf="!page.only_logged_in || logged_in">

            <!-- link .. -->
            <ion-item *ngIf="page.path && !page.children?.length" lines="none" detail="false" button class="separator-bottom" [ngClass]="{'selected': page.path === current_url}" (click)="open_page(page)">
              <ion-label class="ion-text-wrap"><strong>{{ page.title }}</strong></ion-label>
            </ion-item>

            <!-- .. or section -->
            <ion-accordion *ngIf="page.children?.length" class="separator-bottom" toggleIcon="caret-down-outline" [value]="i">
              <ion-item slot="header" lines="none">
                <ion-label class="ion-text-wrap"><strong>{{ page.title }}</strong></ion-label>
              </ion-item>

              <!-- links -->
              <ion-list slot="content">
                <ion-item *ngFor="let child of page.children" lines="none" detail="false" button [ngClass]="{'selected': child.path === current_url}" (click)="open_page(child)">
                  <ion-label class="ion-text-wrap">{{ child.title }}</ion-label>
                  <ion-icon slot="end" *ngIf="child.download" name="cloud-download-outline"></ion-icon>
                </ion-item>
              </ion-list>
            </ion-accordion>
            
          </ng-container>
        </ng-container>
      </ion-accordion-group>

    </nav>
  </ion-content>
</ion-menu>