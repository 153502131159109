import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
} from "@angular/router";
import { Observable, BehaviorSubject, combineLatest } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";

import { AsyncDependencyConsumer } from "../base-classes/async-dependency-consumer";
import { Config } from "../interfaces/config";
import { AccessKeyMode } from "../models/program/access-key-mode.enum";
import { Program } from "../models/program/program.interface";
import { AccessCodeService } from "../services/access-code.service";
import { AlertService } from "../services/alert.service";
import { ConfigService } from "../services/config.service";
import { ProgramService } from "../services/program.service";
import { RouterService } from "../services/router.service";

export enum AccessCodePermission {
  UNRESTRICTED = "unrestricted",
  ANY_ACCESS_CODE = "all",
  CURRENT_PROGRAM = "current_program",
  CURRENT_PROGRAM_IF_RESTRICTED = "access_only_with_program_access_code",
  CUSTOM_ACCESS_CODES = "custom",
}

/**
 * add following to route:
 * {
 *    ...
 *    data: {
 *      use_access_codes: AccessCodePermission,
 *      access_codes: ['GWA', 'Sommer']  // if use_access_codes === AccessCodePermission.CUSTOM
 *    }
 * }
 */
@Injectable({
  providedIn: "root",
})
export class AccessCodeGuard
  extends AsyncDependencyConsumer
  implements CanActivate, CanActivateChild
{
  private is_ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    undefined
  );

  private config_data: Config;

  constructor(
    private program_service: ProgramService,
    private access_code_service: AccessCodeService,
    private config_service: ConfigService,
    private alert_service: AlertService,
    private router_service: RouterService
  ) {
    super();
    this.config_data = this.config_service.config;
    this.init(
      program_service,
      access_code_service,
      alert_service,
      router_service
    );
  }

  protected onReady(): void {
    this.is_ready.next(true);
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const use_access_codes: AccessCodePermission =
      route.data.use_access_codes || AccessCodePermission.UNRESTRICTED;

    return combineLatest([
      this.is_ready,
      this.access_code_service.get_access_codes$(),
    ]).pipe(
      filter(
        ([ready, current_access_codes]) => ready && !!current_access_codes
      ),
      take(1),
      map(([_, current_access_codes]) => {
        const program: Program = this.program_service.get_current_program();
        let allowed_code_names: string[] = [];

        // adapt AccessCodePermission to meet the basic ones without a pending condition
        let scope: AccessCodePermission = use_access_codes;
        if (AccessCodePermission.CURRENT_PROGRAM_IF_RESTRICTED) {
          scope = this.config_data.program_access_only_with_access_code
            ? AccessCodePermission.CURRENT_PROGRAM
            : AccessCodePermission.UNRESTRICTED;
        }
        if (program && program.access_key_mode !== AccessKeyMode.REQUIRED) {
          scope = AccessCodePermission.UNRESTRICTED;
        }

        // get allowed_code_names based on AccessCodePermission
        switch (scope) {
          case AccessCodePermission.ANY_ACCESS_CODE:
            allowed_code_names = current_access_codes.map((code) => code.name);
            break;
          case AccessCodePermission.CURRENT_PROGRAM:
            allowed_code_names = program ? [program.name] : [];
            break;
          case AccessCodePermission.CUSTOM_ACCESS_CODES:
            allowed_code_names = route.data.access_codes;
            break;
        }
        return {
          allowed_code_names,
          current_access_code_names: current_access_codes.map(
            (code) => code.name
          ),
          unrestricted: scope === AccessCodePermission.UNRESTRICTED,
          program_exists: !!program
        };
      }),
      map(
        ({ allowed_code_names, current_access_code_names, unrestricted, program_exists }) =>
          ({
            allowed:
              unrestricted ||
              allowed_code_names.some((allowed) => current_access_code_names.includes(allowed)),

            program_exists
          })
      ),
      tap(({ allowed, program_exists }) => program_exists && !allowed && this.ask_for_access_code()),
      map(({ allowed, program_exists }) => program_exists && allowed)
    );
  }

  private ask_for_access_code() {
    this.alert_service.alert({
      header: "Zugangscode fehlt",
      message: "Um fortzufahren müssen Sie einen gültigen Zugangscode besitzen",
      buttons: [
        {
          text: "Jetzt eingeben",
          handler: () => this.router_service.navigate("/account/access-code"),
        },
        {
          text: "Später",
          role: "cancel",
        },
      ],
    });
  }
}
