import { Injectable, OnDestroy } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import { AsyncDependencyConsumer } from "../base-classes/async-dependency-consumer";
import { Account } from "../models/people/account.class";
import { AccountService } from "../services/account.service";
import { ConfigService } from "../services/config.service";
import { RouterService } from "../services/router.service";

@Injectable({
  providedIn: "root",
})
export class CreateProfileGuard
  extends AsyncDependencyConsumer
  implements CanActivate, CanActivateChild, OnDestroy
{
  private subscriptions: Subscription[] = [];

  private account_valid$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(undefined);

  constructor(
    private acc_service: AccountService,
    private config_service: ConfigService,
    private router_service: RouterService
  ) {
    super();
    this.init(acc_service, router_service);
  }

  protected onReady(): void {
    this.subscriptions.push(
      this.acc_service
        .get_account$()
        .subscribe((account) => this.account_valid$.next(this.check(account)))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Prüft ob alle notwendigen Daten des Profils vorhanden sind
   * used by app component, top-navbar component
   * @returns boolean
   */
  public check(account: Account): boolean {
    if (!account) {
      return true;
    }

    const booking_config = this.config_service.config.booking_settings;

    return [
      !!account,
      account.first_name,
      account.last_name,
      account.phone,
      account.street,
      account.city,
      account.zip_code,
      account.gender ||
        !booking_config ||
        !booking_config.parent_booking_allowed,
    ].every((condition) => condition);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.account_valid$.pipe(
      filter((valid) => valid !== undefined),
      take(1),
      map((valid) => {
        if (!valid && !state.url.startsWith("/account/profile")) {
          this.router_service.navigate("/account/profile", {
            queryParams: { first_login: true },
          });
          return false;
        }
        return true;
      })
    );
  }
}
