export enum Instance {
  AHRENSBOEK = "Ahrensbök",
  BUXTEHUDE = "Buxtehude",
  FLIEDEN = "Flieden",
  FRIESOYTHE = "Friesoythe",
  FULDA = "Fulda",
  GRENZACH_WYHLEN = "Grenzach-Wyhlen",
  HANNOVER = "Hannover",
  HARSEFELD = "Harsefeld",
  HASBERGEN = "Hasbergen",
  HEIDEKREIS = "Heidekreis",
  INGELHEIM = "Ingelheim",
  LANGENFELD = "Langenfeld",
  MARBURG = "Marburg",
  NIEFERN_OESCHELBRONN = "Niefern-Öschelbronn",
  OSNABRUECK = "Osnabrück",
  SCHARBEUTZ = "Scharbeutz",
  VOGELSBERGKREIS = "Vogelsbergkreis",
  WIEFELSTEDE = "Wiefelstede",
  WIESBADEN = "Wiesbaden",

  DEMO = "Demo",
  DEVELOP = "Develop",
}
