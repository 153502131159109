export enum FilterPart {
    PROGRAMS = "programs",

    PERSONS = "persons",
    GENDER = "gender",
    AGE = "age",
    DISABLED = "disabled",

    KIND = "kind",
    CATEGORY = "category",
    PLACES = "places",

    WORD_SEARCH ="word search",
    PRICE = "price",
    DATE = "date"
}
