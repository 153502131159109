import { Component, Input } from "@angular/core";

@Component({
  selector: "app-form-error-message",
  templateUrl: "./form-error-message.component.html",
  styleUrls: ["./form-error-message.component.scss"],
})
export class FormErrorMessageComponent {
  @Input() innerHtml: string;
}
