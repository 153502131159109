import { Injectable } from "@angular/core";

import base_config from "../../assets/config/base.json";
import deployment_config from "../../assets/config/deployment.json";
import { Config } from "../interfaces/config";
import { PriceCategoryWidget } from "../models/booking/price_category_widget.enum";
import { SelectedPriceCatergoryDefault } from "../models/booking/selected_price_category_default.enum";
import { FilterPart } from "../models/etc/filter-part.enum";
import { Instance } from "../models/etc/instance";
import { EventSortDisplayName } from "../models/event/event-sort.class";
import { PriceFormat } from "../models/event/price-format.enum";
import { GalleryType } from "../models/gallery-config/gallery-config.class";
import { ProfileReminderInterval } from "../models/people/account-settings.class";
import { ShowProgramDetails } from "../models/program/show-program-details.enum";
import { PageSection, PopulateDynamic } from "../models/routing/page.interface";


@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private static config_data: Config;


  /** convert json-configs to ts-understandable format. Is called once as provider in app.module! */
  public async load_config(): Promise<void> {

    const gallery: any = base_config.home.slider;
    gallery.display = ConfigService.string_to_enum(
      gallery.display,
      GalleryType
    );

    ConfigService.config_data = {
      ...base_config,
      instanz: ConfigService.string_to_enum(base_config.instanz, Instance),
      home: {
        ...base_config.home,
        slider: gallery,
        show_programdetails: {
          ...base_config.home.show_programdetails,
          kind: ConfigService.string_to_enum(base_config.home.show_programdetails.kind, ShowProgramDetails),
        }
      },
      filter: {
        ...base_config.filter,
        hidden_parts: base_config.filter.hidden_parts
          .map(p => ConfigService.string_to_enum(p, FilterPart))
      },
      sort: ConfigService.string_to_enum(
        base_config.sort,
        EventSortDisplayName
      ),
      price_format: ConfigService.string_to_enum(
        base_config.price_format,
        PriceFormat
      ),
      price_category_widget: ConfigService.string_to_enum(
        base_config.price_category_widget,
        PriceCategoryWidget
      ),
      menu: base_config.menu.map((page) => ({
        ...page,
        populate: (page as PageSection).populate?.map((pop) =>
          ConfigService.string_to_enum(pop, PopulateDynamic)
        ),
      })),
      interval_to_remind_user_of_profile_update: ConfigService.string_to_enum(
        base_config.interval_to_remind_user_of_profile_update,
        ProfileReminderInterval
      ),
      default_selection_of_price_category: ConfigService.string_to_enum(
        base_config.default_selection_of_price_category,
        SelectedPriceCatergoryDefault
      ),

      ...deployment_config,
    };
  }

  public get config(): Config {
    return ConfigService.config_data;
  }

  public static string_to_enum(enum_value: string, enum_class: any) {
    const key = Object.keys(enum_class).find(
      (key) => enum_class[key] === enum_value
    );

    if (!key) {
      throw Error(
        `Could not resolve "${enum_value}" to Enum of config ${enum_class}`
      );
    }
    return enum_class[key];
  }
}
