import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
} from "@angular/router";
import { Observable, combineLatest, from, of } from "rxjs";
import { filter, switchMap, take } from "rxjs/operators";

import { Logger } from "../providers/logger";
import { ProgramService } from "../services/program.service";
import { RouterService } from "../services/router.service";

/**
 * Used as resolver to set the the correct program if :program_id exists on route.
 * Need to use a guard, because resolvers run after all guards and
 * I need the correct program in some guards
 */
@Injectable({
  providedIn: "root",
})
export class SetProgramResolverGuard implements CanActivate, CanActivateChild {
  constructor(
    private program_service: ProgramService,
    private router_service: RouterService
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(childRoute);
  }
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.program_service.is_ready$(),
      this.router_service.is_ready$(),
    ]).pipe(
      filter((are_ready) => are_ready.every((r) => r)),
      take(1),

      switchMap<any, Observable<string>>(() => {
        const program_id = parseInt(route.paramMap.get("program_id"), 10);
        if (!program_id) {
          return of("");
        }

        const current_program = this.program_service.get_program(program_id);
        if (!current_program) {
          Logger.warn(`Program with program_id ${program_id} does not exist`);
          return of("/not-found");
        }
        return from(
          this.program_service
            .set_current_program(current_program)
            .then(() => "")
        );
      }),
      switchMap<string, Observable<boolean>>((redirect) => {
        if (redirect) {
          return from(this.router_service.navigate(redirect).then(() => false));
        }
        return of(true);
      }),
      take(1)
    );
  }
}
