import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { AccessKeyMode } from "../models/program/access-key-mode.enum";
import { AccessCode } from "../models/program/program-extras.interface";
import { Program } from "../models/program/program.interface";

import { AllAccessCodesService } from "./all-access-codes.service";
import { ProgramService } from "./program.service";
import { ShoppingCartService } from "./shopping-cart.service";

@Injectable({
  providedIn: "root",
})
export class AccessCodeService
  extends AsyncDependencyBoth
  implements OnDestroy
{
  private subscriptions: Subscription[] = [];

  private access_codes: BehaviorSubject<AccessCode[]> = new BehaviorSubject<
    AccessCode[]
  >([]);

  private all_access_codes: AccessCode[] = [];
  private program: Program;

  constructor(
    private shopping_cart_service: ShoppingCartService,
    private program_service: ProgramService,
    private all_access_codes_service: AllAccessCodesService
  ) {
    super();
    this.init(shopping_cart_service, program_service, all_access_codes_service);
  }

  protected async onReady(): Promise<void> {
    this.program = this.program_service.get_current_program();

    this.subscriptions.push(
      this.all_access_codes_service.get_all_access_codes$().subscribe((ac) => {
        this.all_access_codes = ac;
        this.update_access_codes();
      }),

      this.program_service.get_current_program$().subscribe((program) => {
        this.program = program;
        this.update_access_codes();
      })
    );

    this.set_ready();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_access_codes$(): Observable<AccessCode[]> {
    return this.access_codes.asObservable();
  }
  public get_access_codes(): AccessCode[] {
    return this.access_codes.getValue();
  }

  private update_access_codes(): void {
    this.access_codes.next(
      this.program
        ? this.all_access_codes.filter(
            (ac) => (ac.program_id === this.program.program_id)
          )
        : []
    );
  }

  /**
   * Zugangscode hat freie Plätze (wie oft kann er noch benutzt werden)
   * checken ob noch freie Plätze vorhanden
   */
  private async get_access_code_free_slots(): Promise<boolean> {
    const event_ids: number[] = this.shopping_cart_service
      .get_shopping_cart()
      .map((cart_item) => cart_item.event_id);

    return Promise.allSettled(
      this.get_access_codes().map((code) =>
        this.all_access_codes_service.check(
          code.code,
          this.program.program_id,
          event_ids
        )
      )
    ).then((res) => {
      return res.every(
        (r) => r.status === "fulfilled" && !r.value.error && r.value.quota_left
      );
    });
  }

  /**
   * prüfe, ob du anhand des access codes Zugang (buchbar?) zum Programm hast
   */
  public check_program_access_based_on_access_code(): boolean {
    const program_access_generally_allowed = this.program?.access_key_mode !== AccessKeyMode.REQUIRED;

    return program_access_generally_allowed || !!this.get_access_codes().length;
  }

  /**
   * prüft ob es überhaupt einen Zugangscode in irgendeinem der Programme gibt
   * used by profile page & account-overview page
   */
  public access_code_allowed_in_some_program(): boolean {
    return this.program_service
      .get_all_programs_including_invisibles()
      .some((program) => program.access_key_mode !== AccessKeyMode.OFF);
  }
}
