import { Component, OnDestroy } from "@angular/core";
import { IonInput, PopoverController } from "@ionic/angular";
import { Subscription } from "rxjs";

import { AsyncDependencyConsumer } from "../../base-classes/async-dependency-consumer";
import { Program } from "../../models/program/program.interface";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { EventFilterDataService } from "../../services/event-filter-data.service";
import { EventsDisplayService } from "../../services/events-display.service";
import { ProgramService } from "../../services/program.service";
import { RouterService } from "../../services/router.service";

import { CollapsibleNavComponent } from "./collapsible-nav/collapsible-nav.component";

@Component({
  selector: "app-top-navbar",
  templateUrl: "./top-navbar.component.html",
  styleUrls: ["./top-navbar.component.scss"],
})
export class TopNavbarComponent
  extends AsyncDependencyConsumer
  implements OnDestroy
{
  // template toggle booleans
  public open_search_on_mobile = false;

  logo: string;
  chosen_program: Program;

  expand_search_on_mobile = false;
  display_program_name = true;

  private subscriptions: Subscription[] = [];

  constructor(
    private router_service: RouterService,
    private config_service: ConfigService,
    private program_service: ProgramService,
    private events_display_service: EventsDisplayService,

    private popover_Controller: PopoverController,

    public event_filter_data_service: EventFilterDataService,
    public auth: AuthService
  ) {
    super();
    this.logo = this.config_service.config.design.logos.top_left;
    this.display_program_name = this.config_service.config.design.top_navbar_show_program;
    this.init(router_service, program_service, events_display_service, auth);
  }

  protected onReady(): void {
    this.subscriptions.push(
      this.program_service
        .get_current_program$()
        .subscribe((program) => (this.chosen_program = program))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  toggle_search(input_tag: IonInput): Promise<void> {
    this.expand_search_on_mobile = !this.expand_search_on_mobile;
    if (this.expand_search_on_mobile) {
      return input_tag.getInputElement().then((el) => el.focus());
    }
  }

  open_search_results(search_text: string): Promise<void> {
    this.event_filter_data_service.options.search_text = search_text;

    if (this.chosen_program) {
      // tell event filter service to update events
      this.events_display_service.update_events();

      return this.router_service.navigate(
        "/events/" + this.chosen_program.program_id
      );
    }
  }

  async open_mobile_popover(event: Event): Promise<void> {
    const popover = await this.popover_Controller.create({
      component: CollapsibleNavComponent,
      cssClass: "top-nav-collapsible",
      componentProps: {
        mobile: true,
        onClick: () => popover.dismiss(),
      },
      event,
    });

    return popover.present();
  }
}
