import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { AlertOptions } from "@ionic/core";

import { AsyncDependencyProducer } from "../base-classes/async-dependency-producer";

@Injectable({
  providedIn: "root",
})
export class AlertService extends AsyncDependencyProducer {
  constructor(private alert_ctrl: AlertController) {
    super();
    this.set_ready();
  }

  public async alert(options: AlertOptions): Promise<HTMLIonAlertElement> {
    const alert = await this.alert_ctrl.create(options);
    await alert.present();

    return alert;
  }
}
