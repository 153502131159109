import { PipeTransform, Pipe } from "@angular/core";
import { EventSort, EventSortDisplayName } from "src/app/models/event/event-sort.class";
import { Event } from "src/app/models/event/event.interface";
import { EventService } from "src/app/services/event.service";

import { Sort, Order } from "../../models/event/sort.class";

@Pipe({
  name: "sort_by",
})
export class SortByPipe implements PipeTransform {

  constructor(private event_service: EventService) { }

  transform<T>(events: T[], sort: Sort<T>): T[] {
    let { comparator } = sort;

    // TODO this is a dirty fix. Ordinary class EventSort won't know event_service.get_free_places,
    // TODO so we substitute the comparator function when needed.
    if ((sort as any as EventSort).display_name === EventSortDisplayName.FREE_PLACES) {
      comparator = event => this.event_service.get_free_places(event as any as Event).free;
    }

    return events.sort((a, b) => {
      const field_a = comparator(a);
      const field_b = comparator(b);

      if (typeof field_a === "string") {
        return sort.order === Order.DESC
          ? field_b.localeCompare(field_a)
          : field_a.localeCompare(field_b);
      }
      return sort.order === Order.DESC ? field_b - field_a : field_a - field_b;
    });
  }
}
