import { Component, ElementRef, OnDestroy, Renderer2 } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { register as registerSwiper } from 'swiper/element/bundle';

import { environment } from "../environments/environment";

import { init_sentry } from "./app.module";
import { AsyncDependencyConsumer } from "./base-classes/async-dependency-consumer";
import { ConfigService } from "./services/config.service";
import { EnvironmentInfoService } from "./services/environment-info.service";
import { PushService } from "./services/push.service";
import { RemindCheckProfileService } from "./services/remind-check-profile.service";
import { RouterService } from "./services/router.service";

registerSwiper();

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent extends AsyncDependencyConsumer implements OnDestroy {
  public skip_link_path: string;
  public watermark: string;
  public show_cookie_footer = false;

  private subscriptions: Subscription[];

  constructor(
    private config_service: ConfigService,
    private env_service: EnvironmentInfoService,
    private router_service: RouterService,
    private _: PushService,   // init PushService on init of app
    private __: RemindCheckProfileService,   // init RemindCheckProfileService on init of app

    private title_service: Title,
    private meta_service: Meta,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    super();
    this.init(env_service, router_service);

    this.el = el;

    // init sentry
    const config = this.config_service.config;
    if (environment.production) {
      init_sentry(config.sentry.environment, config.sentry.release);
    }

    // set website title
    const title = this.config_service.config.wording.title;
    this.title_service.setTitle(title);

    // set Meta tags
    this.config_service.config.meta.forEach(tag => this.meta_service.addTag(tag));
    // ... theme-color
    const doc_style = getComputedStyle(document.documentElement);
    const primary_color = doc_style.getPropertyValue('--ion-color-primary');
    this.meta_service.addTag({name: "theme-color", content: primary_color});
    // ... canonical url
    const canonical_frontend_url = this.config_service.config.urls.canonical_frontend_url;
    if (canonical_frontend_url) {
      this.meta_service.addTag({name: "canonical", content: canonical_frontend_url});
    }

    // load external scripts
    for (const src of config.external_scripts) {

      const script = this.renderer.createElement('script');
      script.async = true;
      script.src = src;
      // script.onload = () => console.log('script loaded');

      this.renderer.appendChild(this.el.nativeElement, script);
    }
  }

  protected onReady(): void {
    // display cookie footer
    this.show_cookie_footer = !this.env_service.is_mobile();

    this.subscriptions = [

      this.router_service.get_current_path$().subscribe(path => {

        // update skip-to-content link on navigation
        this.skip_link_path = `${(path || '').replace(/#main-content/g, '')}#main-content`;
        this.el.nativeElement.querySelectorAll("main").forEach(main => main.setAttribute("id", "main-content"));

        // change watermark randomly
        const watermarks = this.config_service.config.design.watermark || [];
        this.watermark = watermarks.length ? watermarks[Math.floor(Math.random() * watermarks.length)] : null;
      })
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
