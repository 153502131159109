import { Event } from "./event.interface";
import { Order, Sort } from "./sort.class";

export enum EventSortDisplayName {
  START_DATE = "Startdatum",
  ALPHA = "Alphabetisch",
  PRICE_ASC = "Preis: aufsteigend",
  PRICE_DESC = "Preis: absteigend",
  FREE_PLACES = "Freie Plätze",
  RELATIVE_ID = "Kennziffer",
}

export class EventSort extends Sort<Event> {
  private static all_instances: EventSort[] = [
    {
      display_name: EventSortDisplayName.START_DATE,
      comparator: (event: Event) => event.start,
      order: Order.ASC,
    },
    {
      display_name: EventSortDisplayName.ALPHA,
      comparator: (event: Event) => event.name.toLowerCase(),
      order: Order.ASC,
    },
    {
      display_name: EventSortDisplayName.PRICE_ASC,
      comparator: (event: Event) => event.price,
      order: Order.ASC,
    },
    {
      display_name: EventSortDisplayName.PRICE_DESC,
      comparator: (event: Event) => event.price,
      order: Order.DESC,
    },
    {
      display_name: EventSortDisplayName.FREE_PLACES,
      comparator: () => { throw new Error('Need to overwrite comparator for FREE_PLACES in EventSort! (see EventService.get_free_places)') },
      order: Order.DESC,
    },
    {
      display_name: EventSortDisplayName.RELATIVE_ID,
      comparator: (event: Event) => parseInt(event?.relative_id, 10) || -1,
      order: Order.ASC,
    },
  ];

  display_name: EventSortDisplayName;

  static get_all(): readonly EventSort[] {
    return Object.freeze(EventSort.all_instances);
  }

  static get(display_name: EventSortDisplayName): EventSort {
    return Object.freeze(
      EventSort.all_instances.find((sort) => sort.display_name === display_name)
    );
  }
}
