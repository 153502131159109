import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

import { Logger } from "../providers/logger";

/**
 * Subclass this if the class needs time initializing before it may be used properly, so
 * everything is defined before use.
 *
 * AsyncDependencyProducer sets a flag whether it is ready to be used or not.
 * It can be ckeched via is_ready$() and changed by Subclasses via set_ready(ready: boolean)
 * It is used by the AsyncDependencyConsumer
 *
 *
 * @example
 *  class A extends AsyncDependencyProducer {
 *      ...
 *      constructor(...) {
 *          super();
 *          ...
 *          this.set_ready();
 *      }
 *      ...
 *  }
 */
@Injectable({
  providedIn: "root",
})
export abstract class AsyncDependencyProducer {
  private ready: BehaviorSubject<boolean>;
  private timer_until_ready;

  constructor() {
    this.start_timeout_for_ready();
  }

  /**
   * called in constructor to start a timer.
   * If the time is up, it logs a reminder to call this.set_ready().
   */
  protected start_timeout_for_ready(): void {
    this.ready = new BehaviorSubject<boolean>(false);

    const wait_for = 20000;
    this.timer_until_ready = setTimeout(() => {
      if (!this.ready.value) {
        Logger.warn(
          `Do you call this.set_ready() in ${this.constructor.name}? It has been over ${wait_for} ms.`
        );
      }
    }, wait_for);
  }

  /**
   * Call this when you are ready
   *
   * ! if you forget to call this, all dependants down the chain will not execute !
   */
  protected set_ready(): void {
    clearTimeout(this.timer_until_ready);

    if (!this.ready.getValue()) {
      this.ready.next(true);
    }
  }

  /**
   * See if initialization completed
   * @returns whether ready or not
   */
  public is_ready$(): Observable<boolean> {
    return this.ready.asObservable();
  }
}
