import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { Event } from "../models/event/event.interface";

import { AuthService } from "./auth.service";
import { EventService } from "./event.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class ShoppingCartService
  extends AsyncDependencyBoth
  implements OnDestroy
{
  private subscriptions: Subscription[] = [];

  private shopping_cart: BehaviorSubject<Event[]> = new BehaviorSubject<
    Event[]
  >([]);

  private logged_in: boolean;
  private full_shopping_cart: Event[] = [];

  private event_ids_of_current_program: number[] = [];

  constructor(
    private storage_service: StorageService,
    private auth: AuthService,
    private event_service: EventService
  ) {
    super();
    this.init(storage_service, auth, event_service);
  }

  protected onReady(): Promise<void> {
    this.subscriptions.push(
      this.auth.is_logged_in$().subscribe((logged_in) => {
        this.logged_in = logged_in;
        this.set_shopping_cart(this.full_shopping_cart); // display full list if logged in, else []

        // TODO currently no backend to save it in
        // if (!logged_in) { await this.storage_service.delete_value('shopping_cart'); }
      }),

      this.event_service
        .get_events_of_current_program$()
        .subscribe((events) => {
          this.event_ids_of_current_program = events.map(
            (event) => event.event_id
          );
          this.set_shopping_cart(this.full_shopping_cart); // display full list if logged in, else []
        })
    );

    return this.load_from_storage().then(async (shopping_cart) => {
      // set locally & ready for action
      this.set_shopping_cart(shopping_cart);
      this.set_ready();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_shopping_cart$(): Observable<Event[]> {
    return this.shopping_cart.asObservable();
  }
  public get_shopping_cart(): Event[] {
    return this.shopping_cart.getValue();
  }

  private set_shopping_cart(shopping_cart: Event[]): void {
    this.full_shopping_cart = shopping_cart;

    // empty list if not logged in, else wishlist with events of current program
    const sc_of_current_program = this.full_shopping_cart.filter((e) =>
      this.event_ids_of_current_program.includes(e.event_id)
    );
    const sc = this.logged_in ? sc_of_current_program : [];

    this.shopping_cart.next(sc);
  }

  /**
   * entfernt alle Warenkorbelemente aus dem Gerätespeicher
   */
  public clear(): Promise<void> {
    return this.save_to_storage([]);
  }

  public async add(event: Event): Promise<void> {
    const shopping_cart = [...new Set([...this.full_shopping_cart, event])];

    // local && storage
    await this.save_to_storage(shopping_cart);
    this.set_shopping_cart(shopping_cart);
  }

  /**
   * entfernt ein bestimmtes Element aus dem Warenkorb
   */
  public async remove(event: Event): Promise<void> {
    const shopping_cart = this.full_shopping_cart.filter(
      (obj) => obj.event_id !== event.event_id
    );

    // local && storage
    await this.save_to_storage(shopping_cart);
    this.set_shopping_cart(shopping_cart);
  }

  // storage functions

  /**
   * speichert sc auf dem Gerätspeicher
   * wird bei jedem adden / entfernen eines Items aufgerufen
   */
  private save_to_storage(shopping_cart: Event[]): Promise<void> {
    return this.storage_service
      .set(
        "shopping_cart",
        shopping_cart.map((event) => event.event_id)
      )
      .then(() => {
        return;
      });
  }

  /**
   * holt den Warenkorb aus dem Gerätespeicher
   */
  private load_from_storage(): Promise<Event[]> {
    return this.storage_service
      .get<number[]>("shopping_cart")
      .then(async (shopping_cart) => {
        const cart = shopping_cart
          .map((event_id) => this.event_service.get_event_by_id(event_id))
          .filter((event) => event);

        // events differ. Set the ones found by EventService as new ones.
        // Can happen when programs are hidden, events terminated, ...
        if (cart.length !== shopping_cart.length) {
          await this.save_to_storage(cart);
        }

        return cart;
      })
      .catch(() => []); // not set yet
  }
}
