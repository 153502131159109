export enum Order {
  ASC,
  DESC,
}

export class Sort<T> {
  /** used on values on sorting. On usage, a given collection will be sorted by that returned value. */
  comparator: (value: T) => any;
  /** set asc || desc order. */
  order: Order;
}
