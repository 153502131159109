import { Injectable } from "@angular/core";
import { FileOpener } from "@capacitor-community/file-opener";
import {
  Directory,
  Filesystem,
  ReadFileOptions,
  WriteFileOptions,
} from "@capacitor/filesystem";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { RequestOptions } from "../models/backend-call/RequestOptions.interface";
import { Logger } from "../providers/logger";

import { BackendCallService } from "./backend-call.service";
import { EnvironmentInfoService } from "./environment-info.service";

@Injectable({
  providedIn: "root",
})
export class FileService extends AsyncDependencyBoth {
  constructor(
    private backend: BackendCallService,
    private env_info_service: EnvironmentInfoService
  ) {
    super();
    this.init(backend, env_info_service);
  }

  protected onReady(): void {
    this.set_ready();
  }

  /**
   *
   * @param options data has to be base64
   * @returns uri of file in filesystem
   */
  public async write(options: WriteFileOptions): Promise<string> {
    const { uri } = await Filesystem.writeFile(options);
    return uri;
  }

  public async read(options: ReadFileOptions): Promise<string> {
    const { data } = await Filesystem.readFile(options);
    return data;
  }

  /**
   * download from url or path in app and save in cache.
   * fetch_options must contain "responseType": "blob" to work properly!
   * Returns uri to file in cache
   */
  public async download_from_url(
    url: string,
    cache_path_with_filename: string,
    needs_auth_token = false,
    fetch_options: RequestOptions = { responseType: "blob" }
  ): Promise<{ uri: string; mimeType: string }> {
    const blob = needs_auth_token
      ? await this.backend.get_with_token<Blob>(url, fetch_options)
      : await this.backend.get<Blob>(url, fetch_options);

    const base64 = await this.convert_blob_to_base64(blob);

    const uri = await this.write({
      data: base64,
      directory: Directory.Cache,
      path: cache_path_with_filename,
      recursive: true,
    });
    return { uri, mimeType: blob.type };
  }

  // data conversions

  private convert_blob_to_base64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => resolve(reader.result as string);

      reader.readAsDataURL(blob);
    });
  }
  private convert_base64_to_blob(
    base64: string,
    mimeType: string
  ): Promise<Blob> {
    base64 = base64.startsWith("data")
      ? base64
      : `data:${mimeType};base64,${base64}`;

    return fetch(base64).then((res) => res.blob());
  }

  // open

  /**
   * @param uri uri to a file in the filesystem that should be opened
   * @param mime the file's mime type
   */
  public async open(uri: string, mime: string): Promise<void> {
    // on mobile
    if (this.env_info_service.is_cordova()) {
      return this.open_on_mobile(uri, mime);
    }

    // on web

    const base64 = await this.read({ path: uri });

    const blob = await this.convert_base64_to_blob(base64, mime);

    const filename = uri.split("/").reverse()[0];
    this.open_on_web(blob, filename);
  }

  private open_on_mobile(uri: string, mime: string) {
    return FileOpener
      .open({filePath: uri, contentType: mime, openWithDefault: true })
      .then(() => Logger.log(`opened file at ${uri} ${mime}`))
      .catch((err) => Logger.error(`Error opening file ${uri}`, {error: err}));
  }

  private open_on_web(blob: Blob, filename: string) {
    // Edge browser can only generate the pdf with this line
    if (
      blob.type === "application/pdf" &&
      this.env_info_service.is_edge_browser()
    ) {
      (window.navigator as any).msSaveOrOpenBlob(blob, filename);
    }

    // create a-tag to download
    const objectUrl = window.URL.createObjectURL(blob);
    const link_tag = document.createElement("a");
    link_tag.style.display = "none";
    link_tag.href = objectUrl;
    link_tag.download = filename;

    // download
    document.body.appendChild(link_tag);
    link_tag.click();

    // remove the element
    window.URL.revokeObjectURL(objectUrl);
    link_tag.remove();
  }
}
