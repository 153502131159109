import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, combineLatest, Subscription } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import {
  Registration,
  Reservation,
} from "../models/booking/reservation.interface";
import { Event } from "../models/event/event.interface";
import { ParticipantModel } from "../models/registration/participant-model.class";
import { PriceCategory } from "../models/registration/price-category.interface";
import { CheckStep1Service } from "../pages/shopping-cart/prio/check-step1.service";
import { Logger } from "../providers/logger";

import { AccountService } from "./account.service";
import { AuthService } from "./auth.service";
import { BackendCallService } from "./backend-call.service";
import { EventService } from "./event.service";
import { PriceCategoryService } from "./price-category.service";
import { ProgramService } from "./program.service";
import { AccountType } from "./question.service";
import { StorageService } from "./storage.service";

export interface FpRegistrationObject {
  id?: number;
  user?: number;
  child_obj: number;
  other_participant: number;
  registration_object: string; // JSON.stringify(reservation) -> from '../models/booking/reservation.interface'
}
@Injectable({
  providedIn: "root",
})
export class ReservationService
  extends AsyncDependencyBoth
  implements OnDestroy
{
  private subscriptions: Subscription[] = [];

  private reservations: BehaviorSubject<Reservation[]> = new BehaviorSubject<
    Reservation[]
  >(undefined);

  private logged_in: boolean;
  private account_user: number;
  private full_reservations: { [program_id: number]: Reservation[] } = {};

  private program_ids: number[] = [];
  private current_program_id: number;

  constructor(
    private storage_service: StorageService,
    private backend: BackendCallService,
    private auth: AuthService,
    private acc_service: AccountService,
    private program_service: ProgramService,
    private event_service: EventService,
    private price_category_service: PriceCategoryService,
    private check_service: CheckStep1Service
  ) {
    super();
    this.init(storage_service, backend, auth, acc_service, program_service, event_service, price_category_service, check_service);
  }

  protected onReady(): void {
    this.subscriptions.push(
      combineLatest([
        this.auth.is_logged_in$(),
        this.program_service.get_current_program$(),
        this.program_service.get_all_programs$(),
      ]).subscribe(async ([logged_in, program, programs]) => {
        this.logged_in = logged_in;
        this.current_program_id = program ? program.program_id : undefined;
        this.program_ids = programs.map((p) => p.program_id);
        this.account_user = this.acc_service.get_account()?.user || this.account_user;

        // TODO if (logged_in) { sync with backend (somehow) }
        await this.fetch_reservations(this.program_ids, logged_in);

        if (!this.logged_in) {
          await this.delete_from_storage();
        }

        this.set_ready();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private async fetch_reservations(
    program_ids: number[],
    logged_in: boolean
  ): Promise<void> {
    // display full list if logged in, else []
    if (
      !logged_in ||
      !program_ids ||
      Object.keys(this.full_reservations).length
    ) {
      this.update_reservations();
      return;
    }

    // its possible to load reservations, so do it
    return this.load_from_storage().then(async () => {
      // TODO potential probem: backend & storage may go out of sync. Cannot set whole list in backend to change that
      // possible solution: manually check deficites of backend and send individual add/delete calls
      //    OR just use list from backend and ignore storage

      // was empty, get from backend instead
      if (!Object.keys(this.full_reservations).length) {
        await this.load_from_backend();
      }

      // clear warning messages
      Object.values(this.full_reservations).forEach((reservations) =>
        reservations.forEach(
          (reservation) => (reservation.participant as ParticipantModel).warning_messages = [])
      );

      // set locally & ready for action
      this.update_reservations();
    });
  }

  public get_reservations$(): Observable<Readonly<Reservation>[]> {
    return this.reservations.asObservable();
  }
  public get_reservations(): Readonly<Reservation>[] {
    return this.reservations.getValue();
  }

  private set_reservations(reservations: Reservation[]): void {

    // prepare answers in registrations. fixes problem in QuestionsPage, that answers were not unique
    for (const reservation of reservations) {
      for (const registration of reservation.registrations) {
          const questions = registration.custom_questions.map(q => JSON.parse(JSON.stringify({answer: "", ...q})));
          registration.custom_questions = questions;
      }
    }

    // change value
    this.reservations.next(reservations);
  }

  private async load_from_backend() {
    if (!this.logged_in || this.full_reservations) {
      return;
    }

    // get all
    const reservations = await this.api_get_reservations();

    // save all
    await Promise.allSettled(
      Object.entries(reservations).map(([program_id, reservation]) => {
        return this.save_to_storage(parseInt(program_id, 10), reservation);
      })
    ).then();

    // set all
    this.full_reservations = reservations;
  }

  private update_reservations(): void {
    // empty list if not logged in
    const reservations_of_current_program =
      this.full_reservations[this.current_program_id];
    const reservations = this.logged_in
      ? reservations_of_current_program || []
      : [];

    // apply changes
    this.set_reservations(reservations);
  }

  private reservations_list_to_program_id_dict(
    reservations: Reservation[] | Reservation[][]
  ): { [program_id: number]: Reservation[] } {
    if (!reservations?.length) {
      return {};
    }

    // flatten to Reservation[] if necessary
    let flat_reservations: Reservation[];
    if (Array.isArray(reservations[0])) {
      flat_reservations = (reservations as Reservation[][])

        // flatten: Reservation[] | Reservation[][] -> Reservation[]
        .reduce((acc, res) => [...acc, ...res], []);
    } else {
      flat_reservations = reservations as Reservation[];
    }

    return (
      flat_reservations
        .filter((res) => res?.program_id)

        // restructure: Reservation[] -> {[program_id: number]: Reservation[]}
        .reduce((acc, res) => {
          const program_id = parseInt(res.program_id as any as string, 10);
          acc[program_id] = [...(acc[program_id] || []), res];
          return acc;
        }, {})
    );
  }

  public async save_all(
    reservations: Reservation[] = this.get_reservations()
  ): Promise<void> {
    // this.reservation_service.save() is not threadsave
    for (const reservation of reservations) {
      await this.save(reservation);
    }
  }

  public async save(reservation: Reservation): Promise<void> {
    if (!reservation) {
      return;
    }

    // preparation
    const program_id = this.current_program_id;
    if (!reservation.participant.program_id) {
      (reservation.participant as ParticipantModel).program_id = program_id;
    }
    const has_registrations = reservation.registrations?.length;
    let current_reservations: Reservation[] =
      this.full_reservations[program_id] || [];

    if (has_registrations) {
      const is_create = !reservation.id;

      // set price_category
      const price_categories = this.price_category_service.get_price_categories(reservation);

      for (const event_id of Object.keys(price_categories)) {
        const registration = reservation.registrations.find(reg => reg.event_id === parseInt(event_id));
        
        if (!registration.price_category_is_locked && price_categories[event_id]) {
          registration.price_category = price_categories[event_id].id;
          registration.price = price_categories[event_id].price;
        }
      }

      // backend
      const success = await (is_create
        ? this.api_add(reservation)
        : this.api_update(reservation));
      if (!success) {
        return;
      }

      if (is_create) {
        reservation = {
          ...(success as Reservation),
        };
      }
      current_reservations = this.update_or_add_reservations(
        reservation,
        current_reservations
      );

      // storage
      const storage_success = await this.save_to_storage(
        program_id,
        current_reservations
      );
      if (!storage_success) {
        return;
      }
    }

    // local
    this.full_reservations[program_id] = this.update_or_add_reservations(
      reservation,
      current_reservations
    );
    this.update_reservations();
  }


  public async get_reservations_event_can_be_booked_for(event: Event): Promise<Reservation[]> {
    const reg: Registration = Registration.instantiate_from_event(event);
    const reservations: Reservation[] = this.get_reservations();

    return Promise.allSettled(
      reservations.map(res => this.check_service.is_registration_allowed(reservations, res, reg))
    )
    .then((result: PromiseSettledResult<string>[]) => {
      
      return result
        .map((e, i) => ({valid: e.status === "fulfilled" && !e.value, reservation: reservations[i]}))
        .filter(res => res.valid)
        .map(res => res.reservation);
    });
  }

  /**
   * adds reservation to reservations and returns it again while preventing duplicates
   */
  private update_or_add_reservations(
    reservation: Reservation,
    reservations: Reservation[]
  ): Reservation[] {
    const existing_reservation = reservations.find((res) =>
      (res.pk && res.pk === reservation.pk) ||    // slg & children have pks
      (!res.pk && !reservation.pk && res.user === reservation.user)  // account does not. distinguish via .user & !.pk. both are necessary because each child has the same .user as its parent's account
    );

    // update ..
    if (existing_reservation) {
      return reservations.map((res) => (res !== existing_reservation ? res : reservation));
    }

    // .. or add
    return [...reservations, reservation];
  }

  /**
   * entfernt bestimmte Elemente aus dem Warenkorb
   */
  public async remove_multiple(reservations: Reservation[]): Promise<void> {

    if (!reservations?.length) { return; }

    // backend
    for (const reservation of reservations) {
      const success = await this.api_delete(reservation);
      // TODO is very un-pretty. When a person is deleted by the user it is also deleted in the backend.
      // TODO the PrioPage tries to delete it again, therefore this commented-out part. Please review!
      // if (!success) {
      //   alert("deletion failed");
      // }
    }

    // storage
    const program_id = this.current_program_id;
    const deleted_reservation_ids = reservations.map(res => res.id);
    const updated_reservations = (this.full_reservations[program_id] || []).filter(
      (res) => !deleted_reservation_ids.includes(res.id)
    );
    const storage_success = await this.save_to_storage(
      program_id,
      updated_reservations
    );
    if (!storage_success) {
      return;
    }

    // local
    this.full_reservations[program_id] = updated_reservations;
    this.update_reservations();
  }

  /**
   * löscht registration und checkt erneut, ob nächster step nach wie vor möglich ist
   */
  public remove_registration(
    reservation: Reservation,
    registration: Registration
  ): void {
    if (!reservation || !registration) {
      return;
    }

    const reservations = [...this.get_reservations()];
    const own_reservation = reservations.find(
      (r) => r.id === reservation.id
    ) as Reservation;
    if (!own_reservation) {
      return;
    }

    own_reservation.registrations = own_reservation.registrations.filter(
      (reg) => reg.event_id !== registration.event_id
    );
    this.set_reservations(reservations);
  }

  /**
   * entferne alle gruppen codes
   * ("ohne Gruppencodes fortfahren" Button)
   */
  public remove_group_codes(): void {
    const reservations = this.get_reservations();
    reservations.forEach((reservation) =>
      reservation.registrations.forEach(
        (registration) => (registration.group_code = "")
      )
    );

    this.set_reservations(reservations);
  }

  // utility functions to set certain values. Update reservations-observable afterwards

  public set_response_error_message(
    reservation: Reservation,
    message: string
  ): void {
    const reservations: Reservation[] = this.get_reservations();
    const own_reservation = reservations.find((res) => res === reservation);

    own_reservation.response_error_message = message;
    this.set_reservations(reservations);
  }

  public set_calculated_price(reservation: Reservation, price: number): void {
    const reservations: Reservation[] = this.get_reservations();
    const own_reservation = reservations.find((res) => res === reservation);

    (own_reservation.participant as ParticipantModel).calculated_price = price;
    this.set_reservations(reservations);
  }

  public set_token(reservation: Reservation, token: string): void {
    const reservations: Reservation[] = this.get_reservations();
    const own_reservation = reservations.find((res) => res === reservation);

    (own_reservation.participant as ParticipantModel).token = token;
    this.set_reservations(reservations);
  }

  public set_price_category_manually(
    reservation: Reservation,
    registration: Registration,
    price_category: PriceCategory
  ): void {
    if (!reservation || !registration || !price_category) {
      return;
    }

    const reservations: Reservation[] = this.get_reservations();
    const own_reservation = reservations.find((res) => res === reservation);
    const own_registration: Registration = own_reservation.registrations.find(
      (res) => res.event_id === registration.event_id
    );

    // set price cat manually, don't overwrite automatically with something else on save
    own_registration.price_category_is_locked = true;
    own_registration.price = price_category.price;
    own_registration.price_category = price_category.id;

    this.set_reservations(reservations);
  }

  // storage functions

  /**
   * holt den Warenkorb aus dem Gerätespeicher
   */
  private load_from_storage(): Promise<void> {
    return Promise.allSettled(
      this.program_ids.map((p_id) => {
        return this.storage_service
          .get<Reservation[]>(`participants_${this.account_user}_${p_id}`)
          .catch(() => [] as Reservation[]);
      })
    ).then((program_reservations) => {
      // set all
      if (!Object.keys(this.full_reservations).length) {

        const reservations = program_reservations
          .filter((r) => r.status === "fulfilled")
          .map((r: PromiseFulfilledResult<Reservation[]>) => r.value);

        this.full_reservations = this.reservations_list_to_program_id_dict(reservations);
      }
    });
  }

  private save_to_storage(
    program_id: number,
    reservations: Reservation[]
  ): Promise<Reservation[]> {
    reservations
      .filter((res) => !res.program_id)
      .forEach((res) =>
        Logger.info(
          `Reservation of ${res.participant.first_name} lacks a program_id. Skip it`
        )
      );

    if (reservations.some((res) => res.program_id !== program_id)) {
      Logger.error(
        `At least one reservation in wrong program: ` +
          `program_id: ${program_id}, reservations program_ids: ${reservations.map(
            (res) => res.program_id
          )}`
      );

      return;
    }
    return this.storage_service.set<Reservation[]>(
      `participants_${this.account_user}_${program_id}`,
      reservations
    );
  }

  private delete_from_storage(): Promise<void> {
    return Promise.allSettled(
      this.program_ids.map((p_id) => {
        return this.storage_service.remove(`participants_${this.account_user}_${p_id}`);
      })
    ).then();
  }

  // ****************************************
  // ********* API functions start **********
  // ****************************************

  /**
   * holt alle Daten des Warenkorbs vom backend
   * fügt noch notwenige Informationen hinzu
   * speichert die Daten im Gerätespeicher
   * @returns object - wird nicht genutzt
   */
  private api_get_reservations(): Promise<{
    [program_id: number]: Reservation[];
  }> {
    const url = `${this.backend.get_backend_domain()}/api/fp-registration-object/`;

    return (
      this.backend
        .get_with_token<FpRegistrationObject[]>(url)

        // parse registration_object from string to ParticipantModel
        .then((response) =>
          response

            // unpack Json
            .map((res) => {
              const registration_object = {
                ...(JSON.parse(
                  res.registration_object as any as string
                ) as ParticipantModel),
                id: res.id,
              };
              return {
                ...res,
                registration_object,
              };
            })

            // ignore those without reservations
            .filter((res) => res.registration_object.registrations.length)

            // set program_id
            .map((res) => {
              const ERROR = -1;

              // get program_id (from event_ids of registrations if necessary)
              let program_id: number = parseInt(
                res.registration_object.program_id as any as string,
                10
              );
              if (!program_id) {
                program_id = res.registration_object.registrations.reduce(
                  (programm_id, reg) => {
                    if (programm_id === ERROR) {
                      return ERROR;
                    }

                    const program = this.event_service.get_program_on_event_id(
                      reg.event_id
                    );
                    if (!program) {
                      return program_id;
                    }

                    if (
                      programm_id === undefined ||
                      programm_id === program.program_id
                    ) {
                      return program.program_id;
                    }
                    return ERROR;
                  },
                  undefined as number
                );
              }

              // set program_id on object
              return {
                id: res.registration_object.id,
                response: res.registration_object.response,
                registrations: res.registration_object.registrations,

                ...res,
                program_id:
                  program_id && program_id === ERROR ? undefined : program_id,
                participant: res.registration_object.participant, // get participant by res
              };
            })
        )
        .then((reservations: Reservation[]) => {
          return this.reservations_list_to_program_id_dict(reservations);
        })
        .catch((err) => {
          Logger.error("Error in fetching reservations from backend", {error: err});
          return {};
        })
    );
  }

  /**
   * fügt einen Warenkorb zum Profil hinzu
   * used by participant-registration class
   * @params data object mit Warenkorb-Daten
   * @returns number - id des Objekts oder 0
   */
  private api_add(reservation: Reservation): Promise<Reservation> {
    const registration_data: FpRegistrationObject =
      this.to_FpRegistrationObject(reservation);
    const url = `${this.backend.get_backend_domain()}/api/fp-registration-object/`;

    return this.backend
      .post_with_token<{ id: number }>(url, registration_data)
      .then((response) => ({ ...reservation, id: response.id }))
      .catch(() => undefined);
  }

  /**
   * Bestehende Warenkorb-Daten werden überschrieben
   * used by participant registration class
   * @params data object mit Warenkorb-Daten
   * @returns boolean - succes true / fail false
   */
  private api_update(reservation: Reservation): Promise<boolean> {
    const registration_data: FpRegistrationObject =
      this.to_FpRegistrationObject(reservation);
    const url = `${this.backend.get_backend_domain()}/api/fp-registration-object/${
      reservation.id
    }`;

    return this.backend
      .put_with_token(url, registration_data)
      .then(() => true)
      .catch(() => false);
  }

  private async api_delete(reservation: Reservation): Promise<boolean> {

    if (!reservation?.id) { return true; }

    const url = `${this.backend.get_backend_domain()}/api/fp-registration-object/${
      reservation.id
    }/`;

    return this.backend
      .delete_with_token(url)
      .then(() => true)
      .catch(() => false);
  }

  // ****************************************
  // ********* API functions end ************
  // ****************************************

  private to_FpRegistrationObject(
    reservation: Reservation
  ): FpRegistrationObject {
    const account_type = Reservation.get_account_type(reservation);
    return {
      registration_object: JSON.stringify(reservation),
      child_obj:
        account_type === AccountType.CHILD
          ? reservation.participant.pk
          : undefined,
      other_participant:
        account_type === AccountType.SECOND_LEGAL_GUARDIAN
          ? reservation.participant.pk
          : undefined,
    };
  }
}
