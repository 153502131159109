import { Injectable } from "@angular/core";
import { App } from '@capacitor/app';
import { Platform } from "@ionic/angular";

import { AsyncDependencyProducer } from "../base-classes/async-dependency-producer";

@Injectable({
  providedIn: "root",
})
export class EnvironmentInfoService extends AsyncDependencyProducer {

  private app_version: string;

  constructor(private platform: Platform) {
    super();
    this.platform.ready().then(async (ready) => {
      if (!ready) { return; }

      // probably working as App only
      await App.getInfo()
        .then(info => this.app_version = info.version)
        .catch(() => { return; });

      this.set_ready();
    });
  }

  public is_mobile(): boolean {
    return this.platform.is("mobile");
  }

  public is_mobileweb(): boolean {
    return this.platform.is("mobileweb");
  }

  public is_ios(): boolean {
    return this.platform.is("ios");
  }

  public is_android(): boolean {
    return this.platform.is("android");
  }

  public is_cordova(): boolean {
    return this.platform.is("cordova");
  }

  public is_capacitor(): boolean {
    return this.platform.is("capacitor");
  }

  public is_edge_browser(): boolean {
    return window.navigator.userAgent.toLowerCase().includes("edge");
  }

  public is_IE_browser(): boolean {
    const agent = navigator.userAgent.toLowerCase();
    return agent.includes("trident") || agent.includes("msie");
  }

  /**
   * @returns version number or -1 of browser was not IE
   */
  public get_IE_version(): number {
    if (/MSIE (\d+[.\d]*)/.test(navigator.userAgent)) {
      return parseFloat(RegExp.$1);
    }

    // check for IE 11
    if (/Trident.*rv:11\./.test(navigator.userAgent)) {
      return 11;
    }

    return undefined;
  }

  public url(): string {
    return this.platform.url();
  }

  public get_app_version(): string {
    return this.app_version;
  }
}
