export enum Type {
    DEFAULT = "default",
    NEWS = "news"
}

export interface FeriproGenericSite {
    type: "default" | "news";
    id: number;
    title: string;
    description: string;
    created: string; // iso string
}
export interface GenericSite extends FeriproGenericSite {
    type: Type;
    order: number;
}
