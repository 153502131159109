import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Network } from "@capacitor/network";
import { Subscription, firstValueFrom } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { RequestOptions } from "../models/backend-call/RequestOptions.interface";
import { Logger } from "../providers/logger";

import { ConfigService } from "./config.service";
import { TokenService } from "./token.service";

@Injectable({
  providedIn: "root",
})
export class BackendCallService
  extends AsyncDependencyBoth
  implements OnDestroy
{
  private subscriptions: Subscription[] = [];

  private token_prefix = "Bearer ";

  private backend_domain = "";
  private feripro_backend_domain = "";
  private token: string;

  private online: boolean;

  constructor(
    private http: HttpClient,
    private config_service: ConfigService,
    private token_service: TokenService
  ) {
    super();

    const config_data = this.config_service.config;
    this.backend_domain = config_data.urls.backend_domain.replace(/\/$/, "");
    this.feripro_backend_domain =
      config_data.urls.feripro_backend_domain.replace(/\/$/, "");

    this.init(token_service);
  }

  protected async onReady(): Promise<void> {
    this.subscriptions.push(
      this.token_service.get_token$().subscribe((token) => (this.token = token))
    );

    this.online = (await Network.getStatus()).connected;
    Logger.info(this.online ? "initially online" : "initially offline");
    Network.addListener("networkStatusChange", (conn) => {
      this.online = conn.connected;
      Logger.log(this.online ? "online" : "offline");
    });

    this.set_ready();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    Network.removeAllListeners();
  }

  private update_options_with_headers(
    options?: RequestOptions,
    token?: string
  ): RequestOptions {
    let headers: { [header: string]: string } = {};
    if (token) {
      headers = { ...headers, Authorization: this.token_prefix + token };
    }

    if (!options) {
      // construct new option object which only holds headers
      return { headers };
    }

    // use calculated headers and add given options.headers to it.
    // It is intended behaviour that given options.headers may override similarily named ones of headers.
    options.headers = { ...headers, ...options.headers };
    return options;
  }

  public get_backend_domain(): string {
    return this.backend_domain;
  }

  public get_feripro_backend_domain(): string {
    return this.feripro_backend_domain;
  }

  /** GET */

  public get<ReturnType>(
    url: string,
    options: RequestOptions = { responseType: "json" }
  ): Promise<ReturnType> {
    const updatedOptions = this.update_options_with_headers(options);
    return firstValueFrom(this.http.get<ReturnType>(url, updatedOptions));
  }

  public async get_with_token<ReturnType>(
    url: string,
    options: RequestOptions = { responseType: "json" }
  ): Promise<ReturnType> {
    // get the token
    if (!this.token) {
      Logger.error(
        `token could not be found. Don't send get request to ${url}.`
      );
      return null;
    }

    const updatedOptions = this.update_options_with_headers(
      options,
      this.token
    );
    return firstValueFrom(this.http.get<ReturnType>(url, updatedOptions));
  }

  /** POST */

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public post<ReturnType>(
    url: string,
    body?: any,
    options: RequestOptions = { responseType: "json" }
  ): Promise<ReturnType> {
    const updatedOptions = this.update_options_with_headers(options);
    return firstValueFrom(this.http.post<ReturnType>(url, body, updatedOptions));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async post_with_token<ReturnType>(
    url: string,
    body?: any,
    options: RequestOptions = { responseType: "json" }
  ): Promise<ReturnType> {
    // get the token
    if (!this.token) {
      Logger.error(
        `token could not be found. Don't send post request to ${url} with`,
        body
      );
      return null;
    }

    const updatedOptions = this.update_options_with_headers(
      options,
      this.token
    );
    return firstValueFrom(this.http.post<ReturnType>(url, body, updatedOptions));
  }

  /** PUT */

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public put<ReturnType>(
    url: string,
    body?: any,
    options: RequestOptions = { responseType: "json" }
  ): Promise<ReturnType> {
    const updatedOptions = this.update_options_with_headers(options);
    return firstValueFrom(this.http.put<ReturnType>(url, body, updatedOptions));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async put_with_token<ReturnType>(
    url: string,
    body?: any,
    options: RequestOptions = { responseType: "json" }
  ): Promise<ReturnType> {
    // get the token
    if (!this.token) {
      Logger.error(
        `token could not be found. Don't send put request to ${url} with `,
        body
      );
      return null;
    }

    const updatedOptions = this.update_options_with_headers(
      options,
      this.token
    );
    return firstValueFrom(this.http.put<ReturnType>(url, body, updatedOptions));
  }

  /** DELETE */

  public delete<ReturnType>(
    url: string,
    options: RequestOptions = { responseType: "arraybuffer" }
  ): Promise<ReturnType> {
    const updatedOptions = this.update_options_with_headers(options);
    return firstValueFrom(this.http.delete<ReturnType>(url, updatedOptions));
  }

  public async delete_with_token<ReturnType>(
    url: string,
    options: RequestOptions = { responseType: "arraybuffer" }
  ): Promise<ReturnType> {
    // get the token
    if (!this.token) {
      Logger.error(
        `token could not be found. Don't send delete request to ${url}.`
      );
      return null;
    }

    const updatedOptions = this.update_options_with_headers(
      options,
      this.token
    );
    return firstValueFrom(this.http.delete<ReturnType>(url, updatedOptions));
  }
}
