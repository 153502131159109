import { Injectable, OnDestroy } from "@angular/core";
import { Validators } from "@angular/forms";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { FormControlObject, FormControlType } from "../components/form/types";
import { Event } from "../models/event/event.interface";
import { AccountAnswer } from "../models/people/account.class";
import { Program } from "../models/program/program.interface";
import { CustomQuestionType } from "../models/question/custom-question-type.enum";
import {
  CustomQuestion,
  Question,
} from "../models/question/custom-question.interface";
import { Logger } from "../providers/logger";

import { AccountService } from "./account.service";
import { AuthService } from "./auth.service";
import { BackendCallService } from "./backend-call.service";
import { EventService } from "./event.service";
import { ProgramService } from "./program.service";

export enum AccountType {
  OWNER = 1,
  SECOND_LEGAL_GUARDIAN,
  CHILD,
}

export interface Person {
  role: AccountType;
  pk: number;
}

@Injectable({
  providedIn: "root",
})
export class QuestionService extends AsyncDependencyBoth implements OnDestroy {
  private subscriptions: Subscription[] = [];

  private programs: Program[] = [];
  private logged_in: boolean;

  private trigger_questions_changed$: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  private profile_questions: CustomQuestion[];

  private answers: AccountAnswer[];

  private set_profile_questions(questions: CustomQuestion[] = []): void {
    const old_questions = this.profile_questions;

    // skip if no new information
    // Note: undefined !== []. [] means that no question exists, undefined that no information is provided yet!
    if (
      old_questions?.length === questions?.length &&
      questions.every((q) =>
        old_questions.some(
          (old_q) => q.id === old_q.id && q.label === old_q.label
        )
      )
    ) {
      return;
    }

    this.profile_questions = questions || [];
    this.trigger_questions_changed$.next();
  }

  set_answers(answers: AccountAnswer[] = []) {
    this.answers = answers;

    this.trigger_questions_changed$.next();

  }

  constructor(
    private backend: BackendCallService,
    private auth: AuthService,
    private acc_service: AccountService,
    private program_service: ProgramService,
    private event_service: EventService
  ) {
    super();
    this.init(backend, auth, acc_service, program_service, event_service);
  }

  protected onReady(): void {
    this.subscriptions.push(
      this.questions_changed$().subscribe(() => this.check_ready()),

      this.auth
        .is_logged_in$()
        .subscribe((logged_in) => (this.logged_in = logged_in)),

      // get answers
      this.acc_service.get_account$().subscribe(async (account) => {
        const answers = account ? await this.api_get_answers() : [];
        this.set_answers(answers);
      }),

      // get account questions
      this.program_service.get_all_programs$().subscribe((programs) => {
        this.programs = programs || [];
        this.set_profile_questions(
          this.programs.reduce(
            (acc, program) => [...acc, ...program.participant_questions.sort((a, b) => a.id - b.id)],
            []
          )
        );
      }),
    );
  }

  private check_ready() {
    if (this.profile_questions !== undefined && this.answers !== undefined) {
      this.set_ready()
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }


  questions_changed$(): Observable<void> {
    return this.trigger_questions_changed$.asObservable();
  }

  // getters
  public get_profile_questions(person?: Person): Question[] {
    // get correct questions$
    const questions = this.profile_questions;

    const all_answers = this.answers;
    if (questions === undefined || all_answers === undefined) {
      return undefined;
    }

    const answers = this.filter_answers(all_answers, person);

    return questions
      .map((question) => {
        const answer = answers.find(
          (a) => parseInt(a.feripro_question_id, 10) === question.id
        );
        return { question, answer };
      });
  }


  public get_booking_questions(person: Person, event: Event): Question[] {
    // get correct questions$
    const questions = event.registration_custom_fields;
    const all_answers = this.answers;

    if (questions === undefined || all_answers === undefined) {
      return undefined;
    }

    const answers = this.filter_answers(all_answers, person);

    return questions
      .map((question) => {
        const answer = answers.find(
          (a) => parseInt(a.feripro_question_id, 10) === question.id
        );
        return { question, answer: JSON.parse(JSON.stringify(answer || null)) };
      });
  }

  private filter_answers(
    answers: AccountAnswer[],
    person?: Person
  ): AccountAnswer[] {
    if (!person?.role || (!AccountType.OWNER && !person.pk)) {
      return [];
    }

    switch (person.role) {
      case AccountType.OWNER:
        return answers.filter((a) => !a.child && !a.other_participant);
      case AccountType.CHILD:
        return answers.filter(
          (a) => a.child === person.pk && !a.other_participant
        );
      case AccountType.SECOND_LEGAL_GUARDIAN:
        return answers.filter(
          (a) => !a.child && a.other_participant === person.pk
        );
    }
  }

  public is_valid(question: Question): boolean {
    return !question.question.required || !!question.answer?.answer;
  }

  /**
   * speichert die angegebenen Antworten für das angegebene Prifil
   * Profil kann auch ein Kinder-Profil sein
   * used by profile page & second-legal-guardian page
   * @param answers array<object>
   * @param account_data object
   * @returns boolean - success true / fail false
   */
  public save_answers(questions: Question[], person: Person): Promise<boolean> {
    return this.api_save_answers(questions, person)
      .then((saved_answers) => {

        if (saved_answers.length !== questions.length) {
          Logger.error("Not all answers saved");
        }

        // old answers
        const old_answers: AccountAnswer[] = this.answers
          .filter(a => {
            const is_persons_answer =
              (person.role === AccountType.CHILD && a.child === person.pk) ||
              (person.role === AccountType.SECOND_LEGAL_GUARDIAN && a.other_participant === person.pk) ||
              (person.role === AccountType.OWNER && a.other_participant === null && a.child === null);

            // keep all answers of other people
            if (!is_persons_answer) { return true; }

            // keep only answers that have not been newly saved
            return saved_answers.every(new_answer => new_answer.feripro_question_id !== a.feripro_question_id);
          });

        this.set_answers([...old_answers, ...saved_answers]);

        return true;
      })
      .catch(() => false);
  }

  // *************************************
  // **** Backend API functions start ****
  // *************************************

  /**
   * holt die vorhandenen Antworten für alle Nutzer des Profils
   * Kinderantworten werden ebenfalls geholt
   * used by home page, login page, profile page & second-legal-guardian page
   * @returns object - response oder leeres Objekt
   */
  private async api_get_answers(): Promise<AccountAnswer[]> {
    if (!this.logged_in) {
      return [];
    }
    const url = `${this.backend.get_backend_domain()}/api/user/answers/`;

    return this.backend
      .get_with_token<AccountAnswer[]>(url)
      .then((answers) => answers || [])
      .catch((err) => {
        Logger.error("Could not load answers in QuestionService.api_get_answers()", {error: err});
        return [];
      });
  }

  /**
   * speichert neue Antwort oder überschreibt bestehende Antwort
   * @param answers array<object>
   * @param account_data object
   * @returns schließt nur den observable
   */
  private async api_save_answers(
    questions: Question[],
    person: Person
  ): Promise<AccountAnswer[]> {
    const backend_domain = this.backend.get_backend_domain();
    const create_answer_url = `${backend_domain}/api/user/answer/create`;

    return Promise.allSettled(
      questions
        .map((question) => {

          // construct answer
          const answer: AccountAnswer = {
            // answer
            modified_date: undefined,
            creation_date: undefined,
            ...question.answer,

            // question
            feripro_question_id: "" + question.question.id,
            question_text: question.question.label,

            // add to given person
            child: person.role === AccountType.CHILD ? person.pk : null,
            other_participant: person.role === AccountType.SECOND_LEGAL_GUARDIAN ? person.pk : null,
          };

          // look for its id
          const existing_answer = this.answers.find(a => a.feripro_question_id === answer.feripro_question_id && a.child == answer.child && a.other_participant == answer.other_participant);
          const answer_id = question.answer.id || existing_answer?.id || 0;

          // ignore
          if (!answer_id && !answer.answer) {
            return Promise.resolve(null);
          }

          // create
          if (!answer_id) {
            return this.backend.post_with_token(create_answer_url, answer);
          }

          const url = `${backend_domain}/api/user/answer/${answer_id}`;

          // update
          if (answer.answer) {
            return this.backend.put_with_token(url, answer).then(() => ({id: answer_id, ...answer}));
          }
          
          // delete
          return this.backend.delete_with_token(url).then(() => ({id: null, ...answer}));
          
        })
    ).then(answers => 
      
      answers
        .filter(a => a.status === "fulfilled" && a.value)
        .map((a: PromiseFulfilledResult<AccountAnswer>) => a.value)
    )
    .catch(() =>  []);
  }

  // ***********************************
  // **** Backend API functions end ****
  // ***********************************

  public build_control(question: Question): FormControlObject {
    // build basic control
    const template: FormControlObject = {
      key: "",
      type: FormControlType.TEXT,
      options: {},
    };
    const control: FormControlObject = JSON.parse(JSON.stringify(template));

    // add specific values
    control.key = "" + question.question.id;
    control.options.label = question.question.label;
    control.options.default_value = question.answer
      ? question.answer.answer
      : "";

    // add values not common to all question form fields
    if (question.question.required) {
      control.options.validators = [Validators.required];
    }
    if (question.question.description) {
      control.options.info = { title: question.question.description };
    }

    if (question.question.type === CustomQuestionType.ANSWER_CHOICES) {
      control.type = FormControlType.SELECT;
      control.select_options = {
        options: (question.question.choices || []).map((c) => ({
          value: c,
          text: c,
        })),
      };
    }
    if (question.question.type === CustomQuestionType.YES_OR_NO) {
      control.type = FormControlType.SELECT;
      control.select_options = {
        options: [
          { value: "Ja", text: "Ja" },
          { value: "Nein", text: "Nein" },
        ],
      };
    }
    if (
      !question.question.required &&
      control.type === FormControlType.SELECT
    ) {
      control.select_options.options = [
        { value: "", text: "-" },
        ...control.select_options.options,
      ];
    }

    return control;
  }
}
