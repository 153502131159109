import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AsyncDependencyBoth } from '../base-classes/async-dependency-both';
import { Program } from '../models/program/program.interface';
import { Logger } from '../providers/logger';

import { AccountService } from './account.service';
import { AuthService } from './auth.service';
import { BackendCallService } from './backend-call.service';
import { ProgramService } from './program.service';



export class Payment {
  id: number = null;
  account_holder_first_name: string = null;
  account_holder_last_name: string = null;
  account_holder_street: string = null;
  account_holder_zip_code: number = null;
  account_holder_city: string = null;
  account_iban: string = null;
  account_bic: string = null;
}

export enum PaymentMethod {
  CASH = "cash",
  CUSTOM = "custom",
  DEBIT = "debit",
  INVOICE = "invoice",
  ONLINE = "online",
  TRANSFER = "transfer",
}

/**
 * fest gesetztes Model für feripro
 */
export class FeriproPayment {
  method: PaymentMethod = null;
  iban: string = null;
  bic: string = null;
  payer_name: string = null;
  payer_street: string = null;
  payer_city: string = null;
  payer_is_holder: boolean = false;
  holder_name: string = null;
  holder_street: string = null;
  holder_city: string = null;
}


@Injectable({
  providedIn: 'root'
})
export class PaymentService extends AsyncDependencyBoth implements OnDestroy {

  private payment: Payment;

  private subscriptions: Subscription[] = [];

  constructor(
    private backend: BackendCallService,
    private auth: AuthService,
    private acc_service: AccountService,
    private program_service: ProgramService
  ) {
    super();
    this.init(
      backend,
      auth,
      acc_service,
      program_service
    );
  }


  protected onReady(): void {
    this.subscriptions = [
      
      this.auth.is_logged_in$().subscribe(async logged_in => {
        
        if (!logged_in) { this.payment = new Payment(); }
        else {
          await this.api_get_payment().then(payment => this.payment = payment || new Payment());
        }
        
        this.set_ready();
      })
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }


  public get_payment(): Payment {
    return this.payment;
  }

  public update_payment(new_payment: Payment): Promise<boolean> {
    const payment = {...new_payment, id: this.payment.id};

    if (!this.payment.id) {
      return this.api_add(payment);
    }
    return this.api_update(payment);
  }

  public get_FeriproPayment(): FeriproPayment {

    const feripro_payment = new FeriproPayment();
    
    // decide on payment method
    const methods = this.get_active_methods();
    if (!methods.length) {
      feripro_payment.method = PaymentMethod.CASH;
    } else {
      if (methods.length === 1 && methods[0] === PaymentMethod.DEBIT) {
        feripro_payment.method = PaymentMethod.DEBIT;
      } else {
        feripro_payment.method = methods.filter(method => method !== PaymentMethod.DEBIT)[0];
      }
    }

    // set_debit_information
    if (feripro_payment.method === PaymentMethod.DEBIT) {

      if (this.payment?.id) {
        feripro_payment.iban = this.payment.account_iban;
        feripro_payment.bic = this.payment.account_bic;
  
        if (this.payment.account_holder_first_name) {
  
          feripro_payment.holder_name = `${this.payment.account_holder_first_name} ${this.payment.account_holder_last_name}`;
          feripro_payment.holder_street = this.payment.account_holder_street;
          feripro_payment.holder_city = `${this.payment.account_holder_zip_code} ${this.payment.account_holder_city}`;
        } else {
          feripro_payment.payer_is_holder = true;
        }
      }
      const account = this.acc_service.get_account();

      feripro_payment.payer_name = `${account.first_name} ${account.last_name}`;
      feripro_payment.payer_street = account.street;
      feripro_payment.payer_city = `${account.zip_code} ${account.city}`;
    }
    
    return feripro_payment;
  }

  private get_active_methods(program: Program = this.program_service.get_current_program()): PaymentMethod[] {
    return Object.values(PaymentMethod).filter(
      (method) => program.payment_configuration[method].enabled
    );
  }

  public program_payment_is_only_debit(program: Program = this.program_service.get_current_program()): boolean {
    const enabled_payment_services = this.get_active_methods(program);
    return enabled_payment_services.length === 1 && enabled_payment_services[0] === PaymentMethod.DEBIT;
  }

  
  private api_get_payment(): Promise<Payment> {
    const url = `${this.backend.get_backend_domain()}/api/user/bankaccount`;

    return this.backend.get_with_token<Payment>(url)
      .catch(() => {
        Logger.error("Could not load payment from backend");
        return null;
      });
  }


  private api_add(payment: Payment): Promise<boolean> {
    if (!payment) { return; }

    const url = `${this.backend.get_backend_domain()}/api/user/bankaccount/create`;

    return this.backend
      .post_with_token<Payment>(url, payment)
      .then(saved_payment => {
        this.payment = saved_payment;
        return true;
      })
      .catch(err => {
        Logger.error("error adding payment", {error: err})
        return false;
      });
  }

  private async api_update(payment: Payment): Promise<boolean> {
    if (!payment?.id) { return; }

    const url = `${this.backend.get_backend_domain()}/api/user/bankaccount/`;

    // make deletion of single field possible
    if (!payment.account_holder_first_name && this.payment.account_holder_first_name) {
      await this.api_remove();
      return this.api_add(payment);
    }

    // update fields as normal
    return this.backend
      .put_with_token<Payment>(url, payment)
      .then((response) => {
        this.payment = response;
        return true;
      })
      .catch(err => {
        Logger.error("error adding payment", {error: err})
        return false;
      });
  }

  private async api_remove(): Promise<boolean> {

    const url = `${this.backend.get_backend_domain()}/api/user/bankaccount`;

    return this.backend.delete_with_token(url)
      .then(() => true)
      .catch((err) => {
        Logger.error("error on payment deletion:", {error: err});
        return false;
      });
  }
}
