<ion-app class="font-family-class">
  <a class="skip-link" [href]="skip_link_path">Zum Hauptinhalt springen</a>

  <app-top-navbar></app-top-navbar>

  <app-side-menu contentId="main"></app-side-menu>
  <ion-router-outlet id="main"></ion-router-outlet>

  <img *ngIf="watermark" class="watermark-img" [src]="watermark" alt="watermark" aria-hidden="true">

  <footer *ngIf="show_cookie_footer" class="cookie-footer">
    <span class="text">Wir nutzen Cookies um Ihnen die bestmögliche Funktionalität zu bieten. Siehe <a href="/data-protection">Datenschutz</a>!</span>
    <app-button color="light" size="small" (clicked)="show_cookie_footer = false">Ok, verstanden!</app-button>
  </footer>
</ion-app>
