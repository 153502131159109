import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { Event } from "../models/event/event.interface";
import { Logger } from "../providers/logger";

import { AuthService } from "./auth.service";
import { BackendCallService } from "./backend-call.service";
import { EventService } from "./event.service";

@Injectable({
  providedIn: "root",
})
export class WishlistService extends AsyncDependencyBoth implements OnDestroy {
  private subscriptions: Subscription[] = [];

  private wishlist: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>([]);

  private logged_in: boolean;

  constructor(
    private backend: BackendCallService,
    private auth: AuthService,
    private event_service: EventService
  ) {
    super();
    this.init(backend, auth, event_service);
  }

  protected async onReady(): Promise<void> {
    this.subscriptions.push(
      this.auth.is_logged_in$().subscribe((logged_in) => {
        this.logged_in = logged_in;
        this.load_wishlist(); // load from backend if logged_in
      })
    );

    this.set_ready();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_wishlist$(): Observable<Event[]> {
    return combineLatest([
      this.wishlist.asObservable(),
      this.event_service.get_events_of_current_program$(),
    ]).pipe(
      map(([wishlist, events]) => {
        const event_ids = (events || []).map((event) => event.event_id);
        return wishlist.filter((e) => event_ids.includes(e.event_id));
      })
    );
  }
  public get_wishlist(): Event[] {
    const event_ids = this.event_service
      .get_events_of_current_program()
      .map((event) => event.event_id);
    return this.get_full_wishlist().filter((e) =>
      event_ids.includes(e.event_id)
    );
  }
  private get_full_wishlist(): Event[] {
    return this.wishlist.getValue();
  }

  private set_wishlist(wishlist: Event[]) {
    // empty list if not logged in
    this.wishlist.next(this.logged_in ? wishlist : []);
  }

  public async add(event: Event): Promise<void> {
    if (
      !event || !this.logged_in ||
      this.get_wishlist()
        .map((e) => e.event_id)
        .includes(event.event_id)
    ) {
      return;
    }

    // backend, then local
    this.set_wishlist(await this.api_add(event));
  }

  /**
   * entfernt ein bestimmtes Element aus der Wunschliste
   */
  public async remove(event: Event): Promise<void> {
    // Brauche event mit id fuer unser Backend. In der Whishlist ist sie sicher da.
    event = this.get_wishlist().find((e) => (e.event_id === event.event_id));

    if (!event || !this.logged_in) {
      return;
    }

    // backend, then local && storage
    const api_success = await this.api_remove(event);
    if (!api_success) { return; }

    this.load_wishlist();
  }

  private async load_wishlist(): Promise<void> {
  
    // set locally & set ready for action
    this.set_wishlist( this.logged_in ? await this.api_get_wishlist() : [] );
  }


  // api functions

  /**
   * holt die gespeicherten Wunschlisten für den einen Account
   * also inkl. die Veranstaltungen aller Kinder, die in der einen Wunschliste des Accounts mit drin stehen
   * Kinder keine eigenen Accounts mit Wunschliste
   * used by this & app compo
   */
  private api_get_wishlist(): Promise<Event[]> {
    const url = `${this.backend.get_backend_domain()}/api/wishlist`;

    return this.backend
      .get_with_token<{ event_id: number; id: number }[]>(url)
      .then(async (response) => {
        // pass currently available events of recieved event_ids and add their backend-id
        const wishlist = (response || [])
          .map(({ event_id, id }) => ({
            ...this.event_service.get_event_by_id(event_id),
            id,
          }))
          .filter((event) => event.event_id);

        return wishlist;
      })
      .catch(() => {
        Logger.error("Could not load wishlist from backend");
        return [];
      });
  }

  /**
   * fügt eine Veranstaltung zur Wunschliste hinzu
   * Warenkorb wird an einer anderen Stelle gespeichert
   * used by app compo, events page & event page
   */
  private api_add(event: Event): Promise<Event[]> {
    if (!event?.event_id) { return; }

    const url = `${this.backend.get_backend_domain()}/api/wishlist/`;

    return this.backend
      .post_with_token<{ event_id: number; id: number }[]>(url, {
        event_id: event.event_id,
      })
      .then((response) => {
        const wishlist = (response || []).map(({ event_id, id }) => ({
          ...this.event_service.get_event_by_id(event_id),
          id,
        }));
        this.set_wishlist(wishlist);
        return this.get_full_wishlist();
      })
      .catch(err => {

        // TODO prevent adding same event twice =>
        // non_field_errors: ["Die Felder user, event_id müssen eine eindeutige Menge bilden."]
        Logger.error("error adding event to wishlist", {error: err})
        return [];
      });
  }

  /**
   * entfernt eine Veranstaltung aus der Wunschliste
   * Event-Param muss eine id haben (id für db in unserem Backend)
   */
  private async api_remove(event: Event): Promise<boolean> {
    if (!event?.event_id) {
      return false;
    }

    // event came from local storage and has no id as a wishlist item. Try getting it from backend now.
    if (!event.id) {
      event = (await this.api_get_wishlist()).find(
        (e) => e.event_id === event.event_id
      );
      // the event does not exist in the backend's wishlist -> it has not been added or was already deleted before.
      // return with success, since it doesn't exist and that was the goal of this deletion attempt.
      if (!event?.id) {
        return true;
      }
    }

    const url = `${this.backend.get_backend_domain()}/api/wishlist/${event.id}/`;

    return this.backend
      .delete_with_token(url)
      .then(() => true)
      .catch((err) => {
        Logger.error("error on event deletion from wishlist:", {error: err});
        return false;
      });
  }
}
