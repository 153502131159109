import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { BrowserModule, Meta } from "@angular/platform-browser";
import { Router, RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import * as SentryAngular from "@sentry/angular";
import * as Sentry from "@sentry/capacitor";
import { Integrations } from "@sentry/tracing";
import { MarkdownModule, MarkedOptions, MarkedRenderer } from "ngx-markdown";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ButtonComponentModule } from "./components/button/button.module";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { TopNavbarComponentModule } from "./components/top-navbar/top-navbar.module";
import { IsRegistrationDateAllowedPipeModule } from "./pipes/is-registration-date-allowed/is-registration-date-allowed-pipe.module";
import { PricePipeModule } from "./pipes/price/price-pipe.module";
import { SearchPipeModule } from "./pipes/search/search.pipe.module";
import { SortByPipeModule } from "./pipes/sort-by/sort-by-pipe.module";
import { ConfigService } from "./services/config.service";

/** load config before anyone starts and needs it */
export function load_config(config_service: ConfigService): () => Promise<void> {
  return () => config_service.load_config();
}


// SENTRY

/**
 * Init senty integration. Sets environment and Release for Instance.
 */
export function init_sentry(env: string, release: string): void {
  Sentry.init({
      dsn: "https://20db35ad42a84acd837a22eed51d7f58@sentry.zmart-ivent.de/15",
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ["localhost"],
          routingInstrumentation: SentryAngular.routingInstrumentation,
        }),
      ],
      tracesSampleRate: 0.2,
      environment: env,
      release,
    },
    SentryAngular.init
  );
}

/** function that returns `MarkedOptions` with renderer override for whole app */
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  // secure external links against reverse tabnabbing
  renderer.link = (href: string, title: string, text: string): string => {
    // see https://datatracker.ietf.org/doc/html/rfc1738#section-5 for reference
    const alphanum = "0-9a-zA-Z";
    const absolute_url_regex = new RegExp(
      `^[a-z]+://([${alphanum}-]+.)*[${alphanum}-]+`,
      "i"
    );

    if (absolute_url_regex.test(href)) {
      return `<a href="${href || "#"}" title="${
        title || ""
      }" class="md-link" target="_blank" rel="noreferrer">${text || ""}</a>`;
    }
    return `<a href="${href || "#"}" title="${title || ""}" class="md-link">${
      text || ""
    }</a>`;
  };

  return {
    renderer,
    gfm: true,
    breaks: true,
  };
}

@NgModule({
  declarations: [AppComponent, SideMenuComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    MarkdownModule.forRoot({
      // use only one instance to resolve [src] attributes relating to makrkdown formatting
      loader: HttpClient,

      // to keep \n in html (remember to use ngPreserveWhitespaces-directive on tag)
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),

    // for EventsDisplayService
    SortByPipeModule,
    SearchPipeModule,

    // for EventService
    PricePipeModule,

    // for CheckStep1Service
    IsRegistrationDateAllowedPipeModule,

    // for AppComponent
    TopNavbarComponentModule,
    ButtonComponentModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

    // load config
    {
      provide: APP_INITIALIZER,
      useFactory: load_config,
      deps: [ConfigService],
      multi: true
    },

    // Attach Sentry ErrorHandler
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler({ showDialog: true }),
    },

    // Attach Sentry Performance Monitoring
    { provide: SentryAngular.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        return;
      },
      deps: [SentryAngular.TraceService],
      multi: true,
    },

    Meta, // edit Meta-tags
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
