import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { Config } from "../interfaces/config";
import { NumberRange } from "../models/etc/number-range.interface";
import {
  EventSort,
  EventSortDisplayName,
} from "../models/event/event-sort.class";
import { EventType } from "../models/event/event-type.enum";
import { Event } from "../models/event/event.interface";
import { FeriproGender } from "../models/event/feripro-gender.enum";
import { Timespan } from "../models/event/timespan.interface";
import { SearchPipe } from "../pipes/search/search.pipe";
import { SortByPipe } from "../pipes/sort-by/sort-by.pipe";

import { ConfigService } from "./config.service";
import { EventFilterDataService } from "./event-filter-data.service";
import { EventService } from "./event.service";

@Injectable({
  providedIn: "root",
})
export class EventsDisplayService
  extends AsyncDependencyBoth
  implements OnDestroy
{
  private subscriptions: Subscription[] = [];

  public num_unpaginated_events = 0;
  public show_all_events = false;
  public current_page = 1;
  public page_length: number;
  public sort: EventSort;

  private config_data: Config;
  private all_events: Event[] = [];

  private events: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>([]);
  private current_update_process: number;

  constructor(
    private config_service: ConfigService,
    private event_service: EventService,
    private service: EventFilterDataService,

    // pipes
    private sort_by: SortByPipe,
    private filter_search_text_pipe: SearchPipe
  ) {
    super();
    this.config_data = this.config_service.config;
    this.page_length = this.config_data.events_page_events_per_page || 10;
    this.sort = EventSort.get(
      this.config_data.sort || EventSortDisplayName.START_DATE
    );

    this.init(event_service);
  }

  protected onReady(): void {
    this.subscriptions.push(
      this.event_service
        .get_events_of_current_program$()
        .subscribe((events) => {
          this.all_events = events;
          this.update_events();

          this.set_ready();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_events$(): Observable<Event[]> {
    return this.events.asObservable();
  }
  public get_events(): Event[] {
    return this.events.value;
  }

  public update_events(): void {
    const current_update_process = Math.random();
    this.current_update_process = current_update_process;
    let events = this.all_events;

    // filter
    if (this.current_update_process !== current_update_process) { return; }
    events = this.filter_events(events);
    this.num_unpaginated_events = events.length;

    // sort
    if (this.current_update_process !== current_update_process) { return; }
    events = this.sort_events(events);

    // paginate
    if (this.current_update_process !== current_update_process) { return; }
    events = this.show_all_events
      ? events
      : this.paginate_events(events, this.current_page, this.page_length);

    // publish
    if (this.current_update_process !== current_update_process) { return; }
    this.events.next(events);
  }

  private filter_events(events: Event[]): Event[] {
    const filtered_events = [];

    for (const event of events) {
      const day_event = this.filter_duration(
        event,
        this.service.options.period_types
      );

      // tags
      const tags = this.filter_tags(
        event,
        this.service.options.category_tags,
        this.service.options.special_tags
      );

      // organizer
      const organizer = this.filter_organizer(event, this.service.options.organizers);

      // gender
      const gender = this.filter_gender(event, this.service.options.gender);

      // age
      const age = this.filter_age(event, this.service.options.age_range);

      // search text
      const search_text = this.filter_search_text_pipe.transform(
        event,
        this.service.options.search_text
      );

      // booking_state
      const booking_state = 
        !this.service.options.booking_states?.length ||
        this.service.options.booking_states.includes(this.event_service.get_booking_state(event));

      // handicap
      const handicap =
        !this.service.options.only_handicapped_accessible?.length ||
        this.service.options.only_handicapped_accessible
          .every(disability => event.supported_disabilities.some(d => d.value === disability));

      // date (range)
      const date = this.filter_date(event, this.service.options.date_range);

      // price
      const price = this.filter_price(event, this.service.options.price_range);

      // apply filter
      if (
        day_event &&
        tags &&
        organizer &&
        gender &&
        age &&
        search_text &&
        booking_state &&
        handicap &&
        date &&
        price
      ) {
        filtered_events.push(event);
      }
    }
    return filtered_events;
  }

  private sort_events(events: Event[]): Event[] {
    return this.sort_by.transform(events, this.sort);
  }

  private paginate_events(
    events: Event[],
    page: number,
    page_size: number
  ): Event[] {
    const start = (page - 1) * page_size; // (page - 1) is conversion from 1-indexed page to 0-indexed events list
    const end = start + page_size;
    return events.slice(start, end);
  }

  /** filters */

  // age
  /**
   * returns true when event fulfills age requirements
   * @param event to be checked for appropriate age.
   * @param age_range minimum and maximum age that is appropriate.
   */
  private filter_age(event: Event, age_range: NumberRange): boolean {
    const minimum_age_requirement: boolean =
      !age_range.lower ||
      event.max_age == null ||
      event.max_age >= age_range.lower;
    const maximum_age_requirement: boolean =
      !age_range.upper ||
      event.min_age == null ||
      event.min_age <= age_range.upper;

    return minimum_age_requirement && maximum_age_requirement;
  }

  // date
  private filter_date(event: Event, time_window: Timespan<string>): boolean {
    if (
      !this.service.options.date_filter_active ||
      !event.timespans ||
      !event.timespans.length ||
      !time_window
    ) {
      return true;
    }

    const start: number = this.get_time_of_date_without_time(time_window.start);
    const end: number = this.get_time_of_date_without_time(time_window.end);

    for (const timespan of event.timespans) {
      const check_start = this.get_time_of_date_without_time(timespan.start);
      const check_end = this.get_time_of_date_without_time(timespan.end);

      if (time_window.start && check_start && check_start < start) {
        return false;
      }
      if (time_window.end && check_end && check_end > end) {
        return false;
      }
    }

    return true;
  }

  private get_time_of_date_without_time(date: string | Date): number {
    if (!date) {
      return null;
    }

    date = new Date(date);
    return Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0,
      0
    );
  }

  // event-"Art"
  private filter_duration(event: Event, types: EventType[]): boolean {
    if (!event.timespans[0] || !types?.length) {
      return true;
    }

    // get dates without time information
    const [start, end] = [
      event.start.toLocaleDateString("de-DE"),
      event.end.toLocaleDateString("de-DE"),
    ];

    if (
      types.includes(EventType.day_long) &&
      types.length === 1 &&
      start !== end
    ) {
      return false;
    }
    if (
      types.includes(EventType.week_long) &&
      types.length === 1 &&
      start === end
    ) {
      return false;
    }
    return true;
  }

  // gender
  private filter_gender(event: Event, gender: FeriproGender[]): boolean {
    return (
      !gender.length || // if gender filter is not set, get all
      !event.allowed_genders.length || // if no allowed_genders set === no gender restriction?
      gender.every((g) => event.allowed_genders.includes(g))
    ); // if every gender is in allowed_genders
  }

  // price
  private filter_price(event: Event, price_range: NumberRange): boolean {
    if (!this.service.options.price_filter_active) {
      return true;
    }

    const price = parseFloat(event.price as any as string) || 0;
    return price_range.lower <= price && price <= price_range.upper;
  }

  // tags
  private filter_tags(
    event: Event,
    category_tags: string[],
    special_tags: string[]
  ): boolean {
    const special_tag_prerequisite: boolean =
      !special_tags?.length ||
      special_tags.some((tag) => event.tags.includes(tag)); // one tag exists in both lists

    const category_tag_prerequisite: boolean =
      !category_tags?.length ||
      category_tags.some((tag) => event.tags.includes(tag)); // one tag exists in both lists

    return category_tag_prerequisite && special_tag_prerequisite;
  }

  // organizer
  private filter_organizer(event: Event, organizers: Event["organizer"][]): boolean {
    if (!organizers?.length) { return true; }

    return organizers.some(org => org.id === event.organizer.id);
  }
}
