import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { IonAccordionGroup, MenuController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { GenericSite } from "src/app/models/custom-sites/custom-sites";
import { CustomSiteService } from "src/app/services/custom-site.service";
import { FileService } from "src/app/services/file.service";

import { AsyncDependencyConsumer } from "../../base-classes/async-dependency-consumer";
import { Config } from "../../interfaces/config";
import { Program } from "../../models/program/program.interface";
import {
  PageLink,
  PageSection,
  PopulateDynamic,
} from "../../models/routing/page.interface";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { ProgramService } from "../../services/program.service";
import { RouterService } from "../../services/router.service";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent
  extends AsyncDependencyConsumer
  implements OnDestroy, AfterViewInit
{
  @Input() contentId: string;

  @ViewChild(IonAccordionGroup) page_accordion: IonAccordionGroup;

  config_data: Config;
  private programs: Program[] = [];
  private custom_sites: GenericSite[] = [];

  pages: (PageLink | PageSection)[] = [];

  logged_in: boolean;
  current_url: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private config_service: ConfigService,
    private program_service: ProgramService,
    private custom_site_service: CustomSiteService,
    private router_service: RouterService,
    private auth: AuthService,
    private file_service: FileService,

    private router: Router,
    private menu: MenuController
  ) {
    super();
    this.config_data = this.config_service.config;
    this.init(program_service, custom_site_service, router_service, auth, file_service);

    this.subscriptions.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => (this.current_url = event.url))
    );
  }
  ngAfterViewInit(): void {
    // initially expand all accordions
    if (this.page_accordion) {
      this.page_accordion.value = this.pages.map((_, i) => "" + i);
    }
  }

  protected onReady(): void {
    this.subscriptions.push(
      this.program_service.get_all_programs$().subscribe((programs) => {
        this.programs = programs;
        this.set_pages();
      }),
      this.custom_site_service.get_custom_sites$().subscribe((sites) => {
        this.custom_sites = sites.sort((a, b) => a.order - b.order);
        this.set_pages();
      }),
      this.auth.is_logged_in$().subscribe((logged_in) => {
        this.logged_in = logged_in;
        this.set_pages();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private set_pages(): void {

    // add pages from (static) config
    this.pages = this.config_service.config.menu.map((page) => {
      // populate children if necessary
      if ((page as any).populate?.includes(PopulateDynamic.PROGRAMS)) {
        (page as PageSection).children = this.programs.map((program) => {
          return {
            title: program.name,
            path: `/events/${program.program_id}`,
          } as PageLink;
        });
      }

      // get page for display
      return page;
    });

    // add pages from (dynamic) custom_sites
    if (this.custom_sites.some(s => s.type === "news")) {
      this.pages.push({
          title: "News",
          path: `/custom/news`,
        } as PageLink
      );
    }
    this.pages.push(
      ...this.custom_sites.filter(s => s.type !== "news").map(site => ({
        title: site.title,
        path: `/custom/${site.type}/${site.id}`,
      } as PageLink))
    );

    this.ngAfterViewInit();
  }

  close_menu(): Promise<boolean> {
    return this.menu.close();
  }

  async open_page(page: PageLink): Promise<void> {

    if (page.download) {
      const { uri, mimeType } = await this.file_service.download_from_url(
        page.path,
        `menu/${page.title}.pdf`,
        page.only_logged_in,
        {
          headers: { "Content-Type": "application/pdf" },
          responseType: "blob",
        }
      );
  
      return this.file_service.open(uri, mimeType);
    }


    return Promise.allSettled([
      this.close_menu(),
      this.router_service.navigate(page.path),
    ]).then();
  }
}
