export interface AccountSettings {
    id: number;
    last_update_account_information: string;    // iso-date
    allow_send_error_logs: boolean;
    enable_email_notifications: boolean;
    enable_push_notifications: boolean;
    enable_telephone_contact: boolean;
    newsletter_allowed: boolean;
    offline_tickets: boolean;
    photos_allowed: boolean;
    contrast: string;
    font_size: string;
    language: "en"|"de";
}

export enum ProfileReminderInterval {
    ONE_MONTH = "1 month",
    TWO_MONTHS = "2 months",

    INACTIVE = "inactive"
};

/**
 * Returns time interval of ProfileReminderInterval in miliseconds
 * @param interval 
 * @returns number or null if inactive
 */
export function get_interval_in_ms(interval: ProfileReminderInterval): number | null {
    const day_in_ms: number = 1000 * 60 * 60 * 24;

    switch(interval) {
        case ProfileReminderInterval.INACTIVE: return null;

        case ProfileReminderInterval.ONE_MONTH: return day_in_ms * 30;
        case ProfileReminderInterval.TWO_MONTHS: return day_in_ms * 30 *2;

        default: return 0;
    }
}