import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { Account } from "../models/people/account.class";
import { Logger } from "../providers/logger";

import { BackendCallService } from "./backend-call.service";
import { AccountType } from "./question.service";
import { TokenService } from "./token.service";

@Injectable({
  providedIn: "root",
})
export class AccountService extends AsyncDependencyBoth implements OnDestroy {
  private subscriptions: Subscription[] = [];

  /** model anlegen */
  private account: BehaviorSubject<Account> = new BehaviorSubject<Account>(
    undefined
  );
  private second_legal_guardian: BehaviorSubject<Account> =
    new BehaviorSubject<Account>(undefined);
  private token = "";

  constructor(
    private backend: BackendCallService,
    private token_service: TokenService
  ) {
    super();
    this.init(backend, token_service);
  }

  protected async onReady(): Promise<void> {
    this.subscriptions.push(
      // only have account data if token exists (TODO: and is valid)
      this.token_service.get_token$().subscribe(async (token) => {
        if (token === undefined) {
          return;
        }
        this.token = token || "";

        if (!token) {
          this.set_account(undefined);
        }

        await this.set_account(await this.api_get_account_data());
        if (this.get_account()) {
          await this.set_second_legal_guardian(
            await this.api_get_otherparticipants()
          );
        }

        /** the account service ensures that the account & slg exist initially */
        this.set_ready();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_account$(): Observable<Account> {
    return this.account.asObservable();
  }
  public get_account(): Account {
    return this.account.getValue();
  }

  public get_second_legal_guardian$(): Observable<Account> {
    return this.second_legal_guardian.asObservable();
  }
  public get_second_legal_guardian(): Account {
    return this.second_legal_guardian.getValue();
  }

  /**
   * save the account data in the session
   */
  private async set_account(account: Account): Promise<void> {
    if (this.get_account() === account) {
      return;
    }

    if (account) {
      account.type = AccountType.OWNER;
    }

    this.account.next(account);
  }
  /**
   * set the second_legal_guardian data
   */
  private async set_second_legal_guardian(
    second_legal_guardian: Account
  ): Promise<void> {
    if (this.get_second_legal_guardian() === second_legal_guardian) {
      return;
    }

    if (second_legal_guardian) {
      second_legal_guardian.type = AccountType.SECOND_LEGAL_GUARDIAN;
    }

    this.second_legal_guardian.next(second_legal_guardian);
  }

  // *************************************
  // **** Backend API functions start ****
  // *************************************

  /** holt alle Profil-Daten aus der DB
   * @returns object - Profil-Daten oder leeres Objekt
   */
  private async api_get_account_data(): Promise<Account> {
    // check token
    if (!this.token) {
      return null;
    }

    // get backend domain
    const backend_domain: string = this.backend.get_backend_domain();

    return this.backend
      .get_with_token<Account>(backend_domain + "/api/user/accountprofile/")
      .then((account) => Account.from_json(account))
      .catch(() => null);
  }

  /** create an account on the backend
   * @param account object - Profil Objekt
   * @returns string - error message
   */
  public api_create_account(account: {
    email: string;
    password: string;
  }): Promise<string> {
    const url = `${this.backend.get_backend_domain()}/auth/signup/`;

    // set the data to short the post function
    const data = {
      ...account,
      username: account.email,
    };

    // send a request to the backend to signup
    return this.backend
      .post(url, data)
      .then(() => "")
      .catch((err) => {
        let message = "";

        if (err.status === 400) {
          const temp_error = err.error;
          if (temp_error.password) {
            // "Passwort entspricht nicht den Vorraussetzungen.
            // Min. 8 Zeichen, nicht konventionell, nicht nur Zahlen und nicht dem Nutzernamen ähnlich!"
            message = "Passwort: " + temp_error.password;
          }
          if (temp_error.email) {
            message = "Email: Diese Email könnte bereits vergeben sein.";
          }
        } else {
          message = "Account konnte nicht erstellt werden!";
        }

        return message;
      });
  }

  /** überschreibt die Profildaten
   * used by profile page
   * @param object - neue Profil-Daten Objekt
   * @returns object/null
   */
  public api_update_account(account: Account): Promise<void> {
    const url = `${this.backend.get_backend_domain()}/api/user/accountprofile/`;

    return this.backend
      .put_with_token<Account>(url, Account.to_json(account))
      .then((acc) => this.set_account(Account.from_json(acc)))
      .catch((err) => {
        Logger.error("Profil Daten konnten nicht gespeichert werden!", {error: err});
        return null;
      });
  }


  /**
   * @returns error messages if not deleted
   */
  public api_delete_account(password: string): Promise<{errors?: string[], open_bookings?: {[event_id: number]: string}}> {
    const url = `${this.backend.get_backend_domain()}/auth/user/delete`;

    return this.backend
      .post_with_token<Account>(url, { password })
      .then(async () => {
        this.set_account(await this.api_get_account_data());
        return {};
      })
      .catch((err) => {
        const open_bookings = err.error.open_bookings || {};
        delete err.error.open_bookings;
        return {errors: Object.values(err.error), open_bookings};
      });
  }


  /**
   * Erstellt weitere Nutzer und verbindet diese mit dem Profil
   * Zweites Elternteil ist hier gemeint. Es gibt die Möglichkeit mehrere Nutzer hinzuzufügen
   * @param object - zweite Elternteil-Daten Objekt
   * @returns object - response oder leeres Objekt
   */
  public api_create_otherparticipant(
    other_participant: Account
  ): Promise<Account> {
    const url = `${this.backend.get_backend_domain()}/api/user/otherparticipant/create/`;

    return this.backend
      .post_with_token<Account>(url, Account.to_json(other_participant))
      .then((guardian) => {
        guardian = Account.from_json(guardian);
        return this.set_second_legal_guardian(guardian).then(() => guardian);
      })
      .catch(() => null);
  }

  /**
   * Überschreibt zuvor angelegten Nutzer mit den übergebenen Daten
   * @param object - zweite Elternteil-Daten Objekt
   * @returns object - response oder leeres Objekt
   */
  public api_update_otherparticipant(
    other_participant: Account
  ): Promise<Account> {
    const url = `${this.backend.get_backend_domain()}/api/user/otherparticipant/${
      other_participant.pk
    }/`;

    return this.backend
      .put_with_token<Account>(url, Account.to_json(other_participant))
      .then((guardian) => Account.from_json(guardian))
      .catch(() => null);
  }

  /**
   * Holt alle Nutzer die zum Profil hinzugefügt werden
   * used by app component & second-legal-guardian page
   * @returns object - zweites Elternteil Objekt oder leeres Objekt
   */
  public api_delete_otherparticipant(
    other_participant: Account
  ): Promise<Account> {
    const url = `${this.backend.get_backend_domain()}/api/user/otherparticipant/${
      other_participant.pk
    }`;

    return this.backend
      .delete_with_token<void>(url)
      .then(() => {
        this.set_second_legal_guardian(undefined);
      })
      .catch(() => null);
  }

  /**
   * Holt alle Nutzer die zum Profil hinzugefügt werden
   * used by app component & second-legal-guardian page
   * @returns object - zweites Elternteil Objekt oder leeres Objekt
   */
  private api_get_otherparticipants(): Promise<Account> {
    const url = `${this.backend.get_backend_domain()}/api/user/otherparticipants/`;

    return this.backend
      .get_with_token<Account[]>(url)
      .then((second_guardian) => {
        const second_legal_guardian = second_guardian?.length
          ? Account.from_json(second_guardian[0])
          : this.get_second_legal_guardian();
        second_legal_guardian.type = AccountType.SECOND_LEGAL_GUARDIAN;
        return second_legal_guardian;
      })
      .catch(() => null);
  }

  /**
   * Verändert das bestehende PW
   * used by password-change page
   * @param pw_old string
   * @param pw_new string
   * @returns object - response oder error
   */
  public api_password_change(
    pw_old: string,
    pw_new: string
  ): Promise<HttpErrorResponse | { detail: string }> {
    const url = `${this.backend.get_backend_domain()}/auth/password/change/`;

    return this.backend
      .post_with_token<{ detail: string }>(url, {
        old_password: pw_old,
        new_password1: pw_new,
        new_password2: pw_new,
      })
      .catch((err) => err);
  }

  /**
   * Password zurücksetzen Funktion
   * used by password-reset page
   * @param email string
   * @returns object - response oder leeres Objekt
   */
  public api_password_reset(email: string): Promise<any> {
    const url = `${this.backend.get_backend_domain()}/auth/password/reset/`;

    return this.backend.post(url, { email }).catch(() => ({}));
  }

  /**
   * Aktivierungslink erneut senden, da er nach einer Zeit abläuft
   * used by password-reset page & register page
   * @param email string
   * @returns object - response oder leeres Objekt
   */
  public api_resend_activation(email: string): Promise<any> {
    const url = `${this.backend.get_backend_domain()}/auth/resend-activation/`;

    return this.backend.post(url, { email }).catch(() => ({}));
  }

  // ***********************************
  // **** Backend API functions end ****
  // ***********************************
}
