import { captureMessage, SeverityLevel } from "@sentry/angular";
import { environment } from "src/environments/environment";

export class Logger {
  private constructor() { return; }

  public static critical(message: string, optionalParams?: {[_: string]: unknown}): void {
    Logger.send(console.error, 'fatal', message, optionalParams);
  }

  public static error(message: string, optionalParams?: {[_: string]: unknown}): void {
    Logger.send(console.error, 'error', message, optionalParams);
  }

  public static warn(message: string, optionalParams?: {[_: string]: unknown}): void {
    Logger.send(console.warn, 'warning', message, optionalParams);
  }

  public static info(message: string, optionalParams?: {[_: string]: unknown}): void {
    Logger.send(console.info, 'info', message, optionalParams);
  }

  public static log(message: string, optionalParams?: {[_: string]: unknown}): void {
    Logger.send(console.log, 'log', message, optionalParams);
  }

  public static debug(message: string, optionalParams?: {[_: string]: unknown}): void {
    Logger.send(console.debug, 'debug', message, optionalParams);
  }


  private static send(
    develop_log: (message: string, optionalParams?: {[_: string]: unknown}) => void,
    level: SeverityLevel,
    message: string,
    optionalParams?: {[_: string]: unknown}
  ) {
    if (!environment.production) { return optionalParams ? develop_log(message, JSON.parse(JSON.stringify(optionalParams))) : develop_log(message); }

    captureMessage(message, {level, extra: {_: JSON.stringify(optionalParams)}});
  }
}
