<ion-toolbar>

  <nav slot="start">
    <div class="nav-left">
      <ion-buttons size="large">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>

      <a class="logo" href="/" [routerLink]="['/']" routerDirection="back"><ion-img *ngIf="logo" [src]="logo" alt="Logo"></ion-img></a>
    </div>
  </nav>

  <a *ngIf="display_program_name && chosen_program?.name" class="program-name" [ngClass]="{'hidden': expand_search_on_mobile}"
    [href]="'/events/' + chosen_program.program_id" [routerLink]="['/events', chosen_program.program_id]">

    {{ chosen_program.name }}
  </a>

  <div class="button-bar-right" slot="end">
    <!-- searchbar -->
    <div class="search-container" [ngClass]="{'expanded': expand_search_on_mobile}" title="Suche im Programm">
      <button class="search-icon" [attr.aria-label]="expand_search_on_mobile ? 'Suche einklappen' : 'Suche ausklappen'" (click)="toggle_search(searchInput)">
        <ion-icon  name="search-outline"></ion-icon>
      </button>

      <ion-input #searchInput type="search" name="search" aria-label="Suche" placeholder="Suche" class="search-input" [ngClass]="{'expanded': expand_search_on_mobile}"
      (ngModelChange)="open_search_results($event)" [ngModel]="event_filter_data_service.options.search_text"></ion-input>
    </div>

    <!-- desktop -->
    <app-collapsible-nav class="desktop-icons"></app-collapsible-nav>
    <!-- mobile -->
    <button class="mobile-icons navbar-button-badge" (click)="open_mobile_popover($event)" title="Menü-Shortcuts öffnen">
      <ion-icon class="icon" name="ellipsis-vertical-outline"></ion-icon>
    </button>

  </div>
</ion-toolbar>