import { Component, Input } from "@angular/core";
import { Config } from "src/app/interfaces/config";
import { ConfigService } from "src/app/services/config.service";

@Component({
  selector: "app-info-banner",
  templateUrl: "./info-banner.component.html",
  styleUrls: ["./info-banner.component.scss"],
})
export class InfoBannerComponent {
  @Input() markdown_title: string;
  @Input() markdown_text: string;

  public readonly config_data: Config;

  constructor(private config_service: ConfigService) {
    this.config_data = this.config_service.config;
  }
}
