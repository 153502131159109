import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";

import { CollapsibleNavComponent } from "./collapsible-nav/collapsible-nav.component";
import { TopNavbarComponent } from "./top-navbar.component";

@NgModule({
  declarations: [TopNavbarComponent, CollapsibleNavComponent],
  imports: [CommonModule, FormsModule, IonicModule, RouterModule],
  exports: [TopNavbarComponent],
})
export class TopNavbarComponentModule {}
