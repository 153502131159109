import { AccountType } from "src/app/services/question.service";

import { FeriproGender } from "../event/feripro-gender.enum";

export abstract class Person {
  pk: number;

  disabled: boolean;
  handicap_selection: string[]; // string is value from interface Disability
  handicap = "";


  city: string;
  date_of_birth: string;
  district: string;
  first_name: string;
  gender: FeriproGender;
  last_name: string;
  phone: string;
  street: string;
  type: AccountType;
  zip_code: string;

  public static from_json<T extends Person>(person: T): T {
    const copy = { ...person };
    if (copy.handicap) {
      copy.disabled = true;
      return copy;
    }

    switch (copy.disabled as any) {
      case true:
      case false:
        copy.handicap = "";
        break;
      case "false":
      case undefined:
      case null:
        copy.disabled = false;
        copy.handicap = "";
        break;
      case "true":
        copy.disabled = true;
        copy.handicap = "";
        break;
      default:
        copy.handicap = copy.disabled.toString() || "";
        copy.disabled = !!copy.disabled;
    }
    if (!copy.disabled) { copy.handicap_selection = []; }

    return copy as any as T;
  }

  public static to_json<T extends Person>(person: T): T & { disabled: string } {
    const copy: any = { ...person };
    copy.handicap_selection = copy.handicap_selection || [];
    switch (copy.disabled) {
      case true:
      case "true":
        copy.disabled = copy.handicap || "" + copy.disabled;
        break;
      default:
        copy.disabled = "";
        copy.handicap_selection = [];
    }
    return copy as T & { disabled: string };
  }
}
