import { Injectable } from "@angular/core";
import { GetResult, KeysResult, Preferences } from "@capacitor/preferences";

import { AsyncDependencyProducer } from "../base-classes/async-dependency-producer";
import { Logger } from "../providers/logger";

@Injectable({
  providedIn: "root",
})
export class StorageService extends AsyncDependencyProducer {
  private map: Map<string, any> = new Map<string, any>();

  private storage_access_allowed: boolean;

  constructor() {
    super();
    this.init();
  }

  private async init() {
    this.storage_access_allowed = await this.storage_set("testing access", "")
      .then(() => true)
      .catch(() => false);

    if (this.storage_access_allowed) {
      await this.storage_remove("testing access");
    }
    Logger.info(
      `Storage access is${this.storage_access_allowed ? "" : " not"} allowed`
    );

    this.set_ready();
  }

  private is_valid(val: any): boolean {
    return (
      val &&
      (val instanceof Array ? !!val.length : true)
    );
  }

  /**
   * retrieves value from ionic storage. if no value is set there, retrieve value from map. if no value is set there neither, reject.
   * used by app component and some services
   * @param key key to find value
   * @returns Promise which resolves to the value or rejects if the value was either not found, undefined, null, or an empty string.
   */
  public async get<T>(key: string): Promise<T> {
    if (!this.storage_access_allowed) {
      return this.map_get<T>(key);
    }

    return this.storage_get<T>(key).catch((error: string) => {
      try {
        return this.map_get<T>(key);
      } catch (error2) {
        throw Error(error + " && " + error2);
      }
    });
  }

  /**
   * stores value in storage, but only if allowed. if not, stores only in map instead.
   * @param key to store value at
   * @param value value to store
   * @returns Promise which resolves to the value after the value was stored
   */

  public async set<T>(key: string, value: T): Promise<T> {
    this.map_set<T>(key, value);

    if (this.storage_access_allowed) {
      await this.storage_set<T>(key, value);
    }
    return value;
  }

  public async remove(key: string): Promise<void> {
    this.map_remove(key);
    if (this.storage_access_allowed) {
      await this.storage_remove(key);
    }
  }

  /**
   * Clear the entire key value map && storage.
   * @returns Returns a promise that resolves when everything is cleared
   */
  public async clear_all(): Promise<void> {
    this.clear_map();
    if (this.storage_access_allowed) {
      await this.clear_storage();
    }
  }

  // LOOKUP
  /**
   * lookup storage
   */
  private storage_get<T>(name: string): Promise<T> {
    return Preferences.keys().then(({ keys }: KeysResult) => {
      if (!keys.includes(name)) {
        throw Error(`Key '${name}' not found in Storage`);
      }

      return Preferences.get({ key: name }).then(({ value }: GetResult) => {
        const stored_object: T = JSON.parse(value);
        if (!this.is_valid(stored_object)) {
          throw Error(`Value of Key '${name}' has unexpected Format`);
        }
        return stored_object;
      });
    });
  }

  /**
   * lookup map
   */
  private map_get<T>(key: string): T {
    if (this.map.has(key)) {
      const val: T = this.map.get(key);
      if (this.is_valid(val)) {
        return val;
      }
    }
    throw Error(`Key '${key}' not found in Request Scoped Map`);
  }

  // STORE
  /**
   * set storage key
   * @returns the stored value on success
   */
  private storage_set<T>(key: string, value: T): Promise<T> {
    return Preferences.set({ key, value: JSON.stringify(value) }).then(() => value);
  }

  /**
   * set map key
   */
  private map_set<T>(name: string, value: T): Map<string, T> {
    return this.map.set(name, value);
  }

  // DESTROY
  /**
   * destroy storage key
   */
  private storage_remove(key: string): Promise<void> {
    return Preferences.remove({ key });
  }

  /**
   * destroy map key
   */
  private map_remove(key: string): boolean {
    return this.map.delete(key);
  }

  // CLEAR
  /**
   * Clear the entire key value store. WARNING: HOT!
   * @returns Returns void when the store is cleared
   */
  private clear_storage(): Promise<void> {
    return Preferences.clear();
  }

  /**
   * Clear the entire key value map. WARNING: HOT!
   * @returns Returns void when the map is cleared
   */
  private clear_map(): void {
    this.map.clear();
  }
}
