import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { filter, take, map, tap } from "rxjs/operators";

import { ConfigService } from "../services/config.service";
import { RouterService } from "../services/router.service";

/**
 * use on route as:
 *
 *  @example
 * {
 *   path: ...,
 *   loadChildren: ...,
 *   canActivate[Child]: [CustomerGuard],
 *   data: {
 *     allowed_instances: Instance[]
 *   }
 * },
 * ...
 */
@Injectable({
  providedIn: "root",
})
export class CustomerGuard implements CanActivate, CanActivateChild {
  constructor(
    private config_service: ConfigService,
    private router_service: RouterService
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot
  ): boolean | Observable<boolean> {
    return this.canActivate(route);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    const allowed_instances = route.data.allowed_instances || [];
    if (!allowed_instances.length) {
      return true;
    }

    return this.router_service.is_ready$().pipe(
      filter((ready) => ready),
      take(1),
      map(() => allowed_instances.includes(this.config_service.config.instanz)),
      tap((allowed) => {
        if (!allowed) {
          this.router_service.navigate("/not-found");
        }
      })
    );
  }
}
