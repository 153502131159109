import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Observable } from "rxjs";

interface FormObject {
  /**
   * has to be unique in the children of the same FormControlType.GROUP.
   * The value of the related input-field will be returned in a dict on submit with this as key
   */
  key: string;
  /** the FormControlType of this Object. Needed to e.g. determine the form field for the view */
  type: FormControlType;

  /** type-specific options for type == FormControlType.PASSWORD */
  password_options?: { hide: boolean };
  /** type-specific options for type == FormControlType.SELECT */
  select_options?: { options: { value: string; text: string }[] };
  /** type-specific options for type == FormControlType.ARRAY */
  array_options?: {
    title?: string;
    child_title?: string;
  };
  /** type-specific options for type == FormControlType.GROUP */
  group_options?: {
    title?: string;

    /** if true & collapsed is true, the related AbstractControl won't be validated anymore as it and its children are disabled */
    collapsible?: boolean;
    /** if true & collapsible is true, the related AbstractControl won't be validated anymore as it and its children are disabled */
    collapsed?: boolean;
  };
}
export interface FormParsingObject extends FormObject {
  /** used on FormControlType.ARRAY & FormControlType.GROUP for the nested children */
  children: FormParsingObject[];
  /** relation to the actual AbstractControl out of Angular's reactive form context (set on build) */
  form_control?: AbstractControl;
  /** error messages after validation of the form_control */
  errors: string[];

  /** additional settings such as validators & default values for all types */
  options: FormParsingOptions;

  array_options?: {
    title?: string;
    child_title?: string;
    /** template equal to an empty child with no default_values set. Used to generate a new child */
    child_template: FormParsingObject;
  };
}

export interface ArrayChild {
  [key: string]: string | boolean | ArrayChild;
}
/** typing for FormComponent.content. Describes appereance & behavior of a form field */
export interface FormControlObject extends FormObject {
  /** additional settings such as validators & default values for all types */
  options: FormControlOptions;

  array_options?: {
    title?: string;
    child_title?: string;

    /** template equal to an empty child with no default_values set. Used to generate a new child */
    child_template: FormControlObject;
    /** default values for children where a key in the dict is equal to the key of a FormControlObject in the child_template.
     * Nesting is also supported.
     */
    children: ArrayChild[];
  };
  group_options?: {
    title?: string;

    /** if true & collapsed is true, the related AbstractControl won't be validated anymore as it and its children are disabled */
    collapsible?: boolean;
    /** if true & collapsible is true, the related AbstractControl won't be validated anymore as it and its children are disabled */
    collapsed?: boolean;

    children: FormControlObject[];
  };
}

interface FormOptions {
  /** label text displayed with the form field */
  label?: string;
  /** placeholder in the form field. Visible when the input is empty */
  placeholder?: string;

  /** functions to validate the input */
  validators?: ((control: AbstractControl) => ValidationErrors | null)[];
  /** async functions to validate the input */
  async_validators?: ((
    control: AbstractControl
  ) =>
    | Promise<ValidationErrors | null>
    | Observable<ValidationErrors | null>)[];
  /** custom error messages if a validator decides for invalidity */
  validation_error_messages?: { [validation_name: string]: string };

  /**
   * don't display for the user, but be present in the form.
   * Attention: even if hidden is set to true, the validators on ths field will still be in use!
   */
  hidden?: boolean;
  /** text is displayed as info below the form field. title appears on hover */
  info?: { title?: string; text?: string };
}
interface FormControlOptions extends FormOptions {
  /** initial value of the form field */
  default_value?: string | boolean;
}
interface FormParsingOptions extends FormOptions {
  /** initial value of the form field or its children */
  default_value?: string | boolean | ArrayChild | ArrayChild[];
}

/** All possible form fields. Extendable */
// name them with 'label--stacked' at the end to achieve a smaller label in the left upper corner of the respective form field.
export enum FormControlType {
  CHECKBOX = "checkbox",
  DATE = "date label--stacked",
  EMAIL = "email label--stacked",
  NUMBER = "number label--stacked",
  PASSWORD = "password label--stacked",
  SELECT = "select label--stacked",
  TELEPHONE = "tel label--stacked",
  TEXT = "text label--stacked",
  TEXTAREA = "textarea label--stacked",
  TOGGLE = "toggle",

  ARRAY = "array",
  GROUP = "group",
}
