export enum PopulateDynamic {
  PROGRAMS = "programs",
}

export interface PageLink {
  title: string;
  path: string;
  download?: boolean;
  only_logged_in?: boolean;
}

/**
 * Used to display pages in menu (hierarchy).
 */
export interface PageSection {
  title: string;
  children?: PageLink[];
  populate?: PopulateDynamic[];
  only_logged_in?: boolean;
}
