import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() color = "";
  @Input() size = "default";
  @Input() fill = "solid";

  @Input() disabled = false;
  @Input() type = "button";

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
}
