import { Person } from "./person.class";

export interface AccountAnswer {
  id: number;
  feripro_question_id: string;
  question_text: string;
  creation_date: Date;
  modified_date: Date;

  other_participant: number;
  child: number;
  answer: string;
}

export class Account extends Person {
  email: string;

  other_participant: number;

  user: number;
  access_key: string;

  answers: AccountAnswer[];
}
