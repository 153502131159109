import { Image } from "../../components/slider/slider.component";

export enum GalleryType {
  TYPE_OF_EVENTS = "type of events",
  SPECIFIC_EVENTS = "specific events",
  IMAGES = "images",
}

export enum GalleryEventType {
  /** randomly picked events */
  RANDOM = "random",
  /** zeige events mit der wenigsten Auslastung */
  CAPACITY = "capacity",
}
export class GalleryTypeEvents {
  display: GalleryType = GalleryType.TYPE_OF_EVENTS;

  /** typ welche events der slider anzeigt */
  type: GalleryEventType;
  amount: number;
}

export class GallerySpecificEvents {
  display: GalleryType = GalleryType.SPECIFIC_EVENTS;
  /** event_ids to be displayed */
  event_ids: number[];
}

export class GalleryImages {
  display: GalleryType = GalleryType.IMAGES;
  images: Image[];
}
