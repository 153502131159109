import { PipeTransform, Pipe } from "@angular/core";
import { Event } from "src/app/models/event/event.interface";
import { Program } from "src/app/models/program/program.interface";
import { Logger } from "src/app/providers/logger";

@Pipe({
  name: "is_registration_date_allowed",
})
export class IsRegistrationDateAllowedPipe implements PipeTransform {
  private getTime(date: Date): number {
    return date.getTime();
  }

  transform(event: Event, program: Program): boolean {
    if (!event || !program) {
      Logger.warn("IsRegistrationDateAllowedPipe: event or program missing");
      return;
    }

    const now = new Date();
    const start = this.getTime(
      event.first_registration_date || program.start_registration_date || now
    );
    const end = this.getTime(
      event.last_registration_date ||
        program.last_registration_date ||
        event.start ||
        now
    );

    const current_date = this.getTime(now);
    if (event.start && this.getTime(event.start) < current_date) {
      return false;
    }
    return start <= current_date && end >= current_date;
  }
}
